
import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import {postData, getData} from './auxilarFunctions';
import config from '../config.json';
import { Card, CardHeader, Stack, Box, alpha } from '@mui/material';
import el from "date-fns/esm/locale/el/index.js";


let initial_state = {

prescriptions: {
  total: 16540,
  othertotal: 14540,
  male: 344,
  female: 454,
  othermale: 1078,
  otherfemale: 879,
  monthly_avg: 1342,
  active_patients:8156,
  otheractive_patients:10956,
  average_age_patients: 47,
  consultations: 15306,
  otherconsultations: 23700,
  busy_month: "March",
  types:[{
    x: 1,
    y: 1135,
  },{
    x: 2,
    y: 985,
  },{
    x: 3,
    y: 893,
  },{
    x: 4,
    y: 823,
  },{
    x: 5,
    y: 729,
  },{
    x: 6,
    y: 679,
  },{
    x: 7,
    y: 664,
  },{
    x: 8,
    y: 621,
  },{
    x: 9,
    y: 598,
  },{
    x: 10,
    y: 530,
  }],
  othertypes: [535, 1085, 593, 923, 929, 279, 964, 821, 1098, 730,],
  week_days: [135, 35, 50, 170, 135],
  other_week_days: [250, 135, 250, 70, 35],
  type_gms: 1461,
  other_type_gms: 890,
  type_private: 5680,
  other_type_private: 7560,
},
patients:{
  gender_patients: [ 344, 454],
  other_gender_patients: [1078, 879],
  gender_categories: [
    'Male', 
    'Female'
  ],
  gender_colours:[
      
    '#ff8533',
    '#4f9ea9',

  ],
  age_categories: [
    '<1', 
    '1-6',
    '7-17',
    '18-29',
    '30-49',
    '50-69',
    '>=70'
  ],
  age:[234,654,755,234,564,666,543],
  age_donut: [],
  gms_type: [890,900],
  gms_type_donut: [890,900],
  other_gms_type: [890,900],
  gms_colors: [
    '#2C6785',
    '#A5D8D9',
  ],
  gms_categories : ['GMS',  'Private']

  },
consultation:{
  current_year:[],
  previous_year: [],
  years_range: []
},

icpc_consultation:{
  icpccode:[],
  icpcdescription: [],
  icpc_count: []
}
}


export const overview = {
  state: initial_state, // initial state
  reducers: {
    updatePromice1(state,payload) {

      return {
          ...state,
          prescriptions: {
            ...state.prescriptions,
            total: payload.total ? payload.total : 0,
            monthly_avg: payload.monthly_avg ? payload.monthly_avg : 0,
            active_patients: payload.active_patients ? payload.active_patients : 0,
            average_age_patients: payload.average_age_patients ? payload.average_age_patients : 0,
            consultations: payload.consultations ? payload.consultations : 0,
            busy_month: payload.busy_month ? payload.busy_month : '',
          },
        }
    },

    updatePromice2(state,payload) {
      const typesPrescribed = [];
      const prescription_by_type = payload?.prescription_by_type.categories || [];
      const prescription_by_type_count = payload?.prescription_by_type.count || [];

      if(prescription_by_type.length>=1){
        for (let i = 0; i < prescription_by_type.length; i++) 
        {
          if(prescription_by_type_count[i] && prescription_by_type_count[i] !== undefined)
          {
            typesPrescribed.push({
              x: prescription_by_type[i],
              y: prescription_by_type_count[i],
            });
          }
        }
      }else{
        typesPrescribed.push({
          x:'',
          y: 0,
      })
      }

      return {
          ...state,
          prescriptions: {
            ...state.prescriptions,
            types: typesPrescribed,
            week_days: payload.prescription_by_day ? payload.prescription_by_day.count : [],
            
          },
        }
    },

    updatePromice3(state,payload) {

      const patient_count_gender = [];
      const gender_categories = [];
      const patient_num = payload?.patient_count_gender.patient_num || [];

      for (const item of patient_num) {
        if (item.gender && item.count) {
          patient_count_gender.push(parseInt(item.count));
          gender_categories.push(item.gender);
        }
    }

      return {
          ...state,
          patients:{
            ...state.patients,
            gender_patients: patient_count_gender, // payload[1]?.gender_patients,
            gender_categories: gender_categories,
          },
        }
    },

    updatePromice4(state,payload) {

      const patient_count_age = [];
      const patient_num = payload?.patient_count_age.age || [];
      let zero =0;
      let no18 =0;
      let no50 =0;
      let no70 =0;
      for (let i =0; i<patient_num.length ; i++) {
        if (patient_num[i] && i<3) {
          zero = zero + patient_num[i];
        } else if (patient_num[i] && i<5) {
          no18 = no18 + patient_num[i];
        } else if (patient_num[i] && i==5) {
          no50 = no50 + patient_num[i];
        } else {
          no70 = no70 + patient_num[i];
        }
    }
    patient_count_age.push(zero);
    patient_count_age.push(no18);
    patient_count_age.push(no50);
    patient_count_age.push(no70);

      return {
          ...state,
          patients:{
            ...state.patients,
            age:patient_num,
            age_donut:patient_count_age,
          },
        }
    },

    updatePromice5(state,payload) {

        const patient_gms_type = [2];
        const patient_gms_type_donut = [2];
        const patient_num = payload?.patient_count_type.gms_type || [];
  
        for (const item of patient_num) {
          if (item.gmsstatus && item.gmsstatus=="1") {
            patient_gms_type[0]=item.count;
            patient_gms_type_donut[0]=parseInt(item.count);
            
          }
          if (item.gmsstatus && item.gmsstatus=="2") {
            patient_gms_type[1]=item.count;
            patient_gms_type_donut[1]=parseInt(item.count);
          }
        }
  
        return {
          ...state,
          patients:{
            ...state.patients,
            gms_type: patient_gms_type,
            gms_type_donut: patient_gms_type_donut
          },
        }
      },

    updatePromice6(state,payload) {

      //   const patient_gms_type = [2];
      //   const patient_num = payload?.patient_count_type.gms_type || [];
  
      //   for (const item of patient_num) {
      //     if (item.gmsstatus && item.gmsstatus=="1") {
      //       patient_gms_type[0]=item.count;
      //     }
      //     if (item.gmsstatus && item.gmsstatus=="2") {
      //       patient_gms_type[1]=item.count;
      //     }
      // }
  
      return {
          ...state,
          consultation: {
            ...state.consultation,
            previous_year: payload.consultations_by_year.groupedYear,
            current_year: payload.consultations_by_year.groupedYear,
            years_range: payload.consultations_by_year.years
          }
        }
    },  

    updatePromice7(state, payload) {
      const codedICPC = payload.coded_icpc;
    
      const icpcCodes = codedICPC?.map(item => ({
        code: item.icpccode,
        description: item.icpcdescription,
        count: item.icpc_count
      }));
    
      return {
        ...state,
        icpc_consultation: {
          ...state.icpc_consultation,
          icpccodes: icpcCodes?.map(code => code.code),
          icpc_descriptions: icpcCodes?.map(code => code.description),
          icpc_counts: icpcCodes?.map(code => code.count)
        }
      };
    },     
  
    updateDetails(state, payload){

      return {
        prescriptions: {
          total: payload[0].total,
          othertotal: 14540,
          male: 344,
          female: 454,
          othermale: 1078,
          otherfemale: 879,
          monthly_avg: payload[0].monthly_avg,
          active_patients:payload[0].active_patients,
          otheractive_patients:10956,
          average_age_patients: payload[0].average_age_patients,
          consultations: payload[0].consultations,
          otherconsultations: 23700,
          busy_month: payload[0].busy_month,
          types: payload[1].types,
          othertypes: [535, 1085, 593, 923, 929, 279, 964, 821, 1098, 730,],
          types_categories: payload[1].types_categories,
          week_days: [payload[1].week_days[1],payload[1].week_days[2],payload[1].week_days[3],payload[1].week_days[4],payload[1].week_days[5]],
          other_week_days: [250, 135, 250, 70, 35],
          type_gms: 1461,
          other_type_gms: 890,
          type_private: 5680,
          other_type_private: 7560,
        },
        patients:{
          gender_patients: [payload[2].gender_count[0],payload[2].gender_count[1]], // payload[1]?.gender_patients,
          gender_categories: [payload[2].gender_categories[0],payload[2].gender_categories[1]],
          other_gender_patients: [1078, 879],
          gender_colours:[
              
            '#4f9ea9',
            '#ff8533',
            '#3E8397', 
            '#3E8397'
          ],
          age_categories: [
            '<1', 
            '1-6',
            '7-17',
            '18-29',
            '30-49',
            '50-69',
            '>=70'
          ],
          age:payload[3].age,
          age_donut:payload[3].age_donut,
          age_colours: ['#3E8397', '#3E8397', '#3E8397', '#5ca8bc', '#5ca8bc', '#92c5d3', '#c9e2e9'],
          age_colours_comparison: [[ "#3E8397",alpha("#3E8397", 0.5)], ["#3E8397",alpha("#3E8397", 0.5)],[ "#3E8397",alpha("#3E8397", 0.5)],["#5ca8bc",alpha("#5ca8bc", 0.5)], ["#5ca8bc",alpha("#5ca8bc", 0.5)], ["#92c5d3",alpha("#92c5d3", 0.5)],["#c9e2e9",alpha("#c9e2e9", 0.5)]],
          
          gms_type: payload[4].gms_type,
          other_gms_type: [890,900],
          gms_colors: [
            '#2C6785',
            '#A5D8D9',
          ],
          gms_categories : ['GMS',  'Private']
        },
        consultation: {
          previous_year: payload[5].year1,
          current_year: payload[5].year2,
          years_range: payload[5].years
        }
      }
      }
    },
   
  effects: (dispatch) => ({
    
    async getPromise_1(payload, state) {

      try {
        const promise_1 =await postData(config.url +'/charts/poverview1', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice1(promise_1); 
      } catch (error) {
        return error;
      }
    },

    async getPromise_2(payload, state) {

      try {
        const promise_2 =await postData(config.url +'/charts/poverview2', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice2(promise_2); 
      } catch (error) {
        return error;
      }
    },
  

    async getPromise_3(payload, state) {

      try {
        const promise_3 =await postData(config.url +'/charts/poverview3', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice3(promise_3); 
      } catch (error) {
        return error;
      }
    },

    async getPromise_4(payload, state) {

      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age
        let ageIndex= null
        ageIndex = ageRange.length > 0 ? ageRange.map(value =>{
                      return ageValues.indexOf(value[0]);
                    }) 
                    :null
  
        const promise_4 =await postData(config.url +'/charts/poverview4', {gms:state.filters.gms, age:ageIndex, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice4(promise_4); 
      } catch (error) {
        return error;
      }
    },

    async getPromise_5(payload, state) {

      try {
        const promise_5 =await postData(config.url +'/charts/poverview5', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice5(promise_5); 
      } catch (error) {
        return error;
      }
    },

    async getPromise_6(payload, state) {

      try {
        const promise_6 =await postData(config.url +'/charts/poverview6', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updatePromice6(promise_6);
        return; 
      } catch (error) {
        return error;
      }
    },
  
    async getPromise_7(payload, state) {

      try {
        const promise_7 =await postData(config.url +'/charts/poverview7', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token);
        return await this.updatePromice7(promise_7);
      } catch (error) {
        return error;
      }
    },
     
    loadData(payload, state) {
      //console.log('loadData pO');

      // const promise_2 = postData('http://localhost:3000/charts/poverview2', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender})
      // const promise_3 = postData('http://localhost:3000/charts/poverview3', {gms:state.filters.gms, age:state.filters.age})
      // const promise_4 = postData('http://localhost:3000/charts/poverview4', {gms:state.filters.gms, gender:state.filters.gender})
      // const promise_5 = postData('http://localhost:3000/charts/poverview5', {age:state.filters.age, gender:state.filters.gender})
      // const promise_6 = postData('http://localhost:3000/charts/poverview6', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender})
      // return Promise.all([promise_1, promise_2, promise_3, promise_4, promise_5, promise_6]).then(data => {
      //    console.log("sucess !!");
      //    console.log(data, "  <--- data pO");
      //    this.updateDetails(data)
    
      // });
      return;

    },
    
  })
};

// let months = ["January", "March", "December"]


// // count model
// export const overview = {
//     state: initial_state,

//     reducers: {
//       updateDetails(state, payload){
//         return {
//           prescriptions: {
//             total: payload.total,
//             monthly_avg: payload.monthly_avg,
//             active_patients:payload.active_patients,
//             average_age_patients: payload.average_age_patients,
//             consultations: payload.consultations,
//             busy_month: payload.busy_month,
//             types: [1135, 985, 893, 823, 729, 679, 664, 621, 598, 530,],
//             week_days: [135, 35,50, 170, 235],
            
//           },
//         }
//       },
//       updatePractice(state, payload) {
//         let indexes_sex_filters =  []
//         initial_state.patients.gender_categories.forEach(((cat, index)=>{
//           if(payload.all_state.filters.gender.includes(cat)) {
//             indexes_sex_filters.push(index);
//           }
//         })) 

//         if(indexes_sex_filters.length == 0) {
//           indexes_sex_filters=[0,1]
//         }
//         console.log("indexes_sex_filters", indexes_sex_filters)
//         let indexes_ages_filters =  []
//         initial_state.patients.age_categories.forEach(((cat, index)=>{
//           if(payload.all_state.filters.age.includes(cat)) {
//             indexes_ages_filters.push(index);
//           }
//         })) 

//         if(indexes_ages_filters.length == 0) {
//           indexes_ages_filters=[0,1,2,3,4,5,6]
//         }

//         let indexes_gms_filters =  []
//         initial_state.patients.gms_categories.forEach(((cat, index)=>{
//           if(payload.all_state.filters.gms.includes(cat)) {
//             indexes_gms_filters.push(index);
//           }
//         })) 

//         if(indexes_gms_filters.length == 0) {
//           indexes_gms_filters=[0,1]
//         }

//         return {
//           ...state,
//           prescriptions: {
//             total: Math.round(initial_state.prescriptions.total*payload.percentage),
//             monthly_avg: Math.round(initial_state.prescriptions.monthly_avg*payload.percentage),
//             active_patients:Math.round(initial_state.prescriptions.active_patients*payload.percentage),
//             average_age_patients: Math.round(initial_state.prescriptions.average_age_patients*payload.percentage),
//             consultations: Math.round(initial_state.prescriptions.consultations*payload.percentage),
//             busy_month: months[Math.floor(Math.random() * months.length)],
//             types:initial_state.prescriptions.types.map(m=> Math.round(m*(payload.percentage))),
//             week_days:initial_state.prescriptions.week_days.map(m=> Math.round(m*(payload.percentage)*0.95)),
//           },
//           patients:{
//             gender_patients: initial_state.patients.gender_patients.filter((cat, idx)=>indexes_sex_filters.includes(idx)).map(cat=>Math.round(cat*(payload.percentage)*0.95)), 
//             gender_categories: payload.all_state.filters.gender.length > 0 ? payload.all_state.filters.gender : initial_state.patients.gender_categories  ,
//             gender_colours: initial_state.patients.gender_colours.filter((cat, idx)=>indexes_sex_filters.includes(idx)),
//             age_categories:  payload.all_state.filters.age.length > 0 ? payload.all_state.filters.age : initial_state.patients.age_categories,
//             age: initial_state.patients.age.filter((cat, idx)=>indexes_ages_filters.includes(idx)),
//             gms_type: initial_state.patients.gms_type.filter((cat, idx)=>indexes_gms_filters.includes(idx)).map(cat=>Math.round(cat*(payload.percentage)*0.95)),
//             gms_colors: initial_state.patients.gms_colors.filter((cat, idx)=>indexes_gms_filters.includes(idx)),
//             gms_categories :initial_state.patients.gms_categories.filter((cat, idx)=>indexes_gms_filters.includes(idx))
//           }
//         }
//       }
    

//     },
//     effects: (dispatch)=> ({
//       // getPractice(payload, state) {
       
//       //   console.log("state", state)
//       //   let filters = 0;
//       //   let total_filters = 0;
//       //   if(state.filters.age.length > 0){
//       //     filters = filters + state.filters.age.length
//       //     total_filters = total_filters + 7
//       //   }
//       //   if(state.filters.gender.length > 0){
//       //     filters = filters + state.filters.gender.length
//       //     total_filters = total_filters + 3
//       //   }
//       //   if(state.filters.gms.length > 0){
//       //     filters = filters + state.filters.gms.length
//       //     total_filters = total_filters + 2
//       //   }
//       //   if (filters > 0 && total_filters > 0) {
//       //     dispatch.overview.updatePractice({percentage: filters/total_filters, all_state: state });
//       //   } else {
//       //     dispatch.overview.updatePractice({percentage: 1 , all_state: state});

//       //   }
//       // },
//     loadData(payload, state) {
      
//       const promise_1 = postData('http://localhost:3000/charts/poverview1', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender})
//       const promise_2 = postData('http://localhost:3000/charts/poverview3', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender})
     
//       return Promise.all([promise_1, promise_2]).then(data => {
//          console.log("sucess");
//          this.updateDetails(data)
    
//       });
//     }
//     }
//     )
// };