import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, Switch, TextField, IconButton, FormGroup, FormHelperText, InputAdornment, Checkbox, FormControl, FormControlLabel, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import Select from '@mui/material/Select';
import { connect } from "react-redux";
import { id } from 'date-fns/locale';



// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const practicesetup = function PracticeSetUp(props) {
    const navigate = useNavigate();
    const [hospitals, setHospitals] = useState([]);
    const [labs, setLabs] = useState([]);
    const [place, setPlace] = useState([]);
  
    useEffect(async () => {
        if(props.practice.hospitals && props.practice.hospitals !== undefined){
            setHospitals(props.practice.hospitals);
        }
      }, [props.practice.hospitals]);

      useEffect(async () => {
          if(props.practice.places && props.practice.places !== undefined){
            setPlace(props.practice.places);
          }
        }, [props.practice.places]);

        useEffect(async () => {
            if(props.practice.laboritires && props.practice.laboritires !== undefined){
                setLabs(props.practice.laboritires);
            }
          }, [props.practice.laboritires]);
          
    useEffect(async () => {
        await props.getHospitals();
        await props.getLaboratories();
        await props.getPlaces();
      }, []);

    const RegisterSchema = Yup.object().shape({

        contactperson: Yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Contact person required'),
        gpsnumber: Yup.number()
            .min(1, 'At least one!')
            .required('Gpsnumber required'),
        nursesnumber: Yup.number()
            .min(1, 'At least one!')
            .required('Nurses required'),
        eircode: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Eircode required'),
        mainhospitalid: Yup.string()
            .required('Main Hospital required'),
        placeid: Yup.string()
            .required('Place required'),
        laboratoryid: Yup.string()
            .required('Laboratory required'),
        termsAccepted: Yup.boolean()
            .oneOf([true], 'Field must be checked')

    });

    const formik = useFormik({
        initialValues: {
            contactperson: '',
            gpsnumber: '',
            nursesnumber: '',
            manager: false,
            eircode: '',
            mainhospitalid: null,
            secondhospitalid: null,
            placeid: null,
            laboratoryid: null,
            dispensingpractice: false,
            termsAccepted: false
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            // navigate('/dashboard', { replace: true });
            const responce = await props.compleatedetails(values);
            if(responce){
                navigate('/dashboard/antibiotics');
            }
            // handleOpen(0)
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Hi, Welcome back. Before showing you more, we need you to complete these details: </Typography>
                </Box>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}  >
                    <Grid item xs={12} sm={6} md={6}>
                        <FormikProvider value={formik}>

                            <Form autoComplete="off" onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                            fullWidth
                                            label="Practice name"
                                            disabled={true}
                                            value={props.user.username}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Contact person"
                                            {...getFieldProps('contactperson')}
                                            error={Boolean(touched.contactperson && errors.contactperson)}
                                            helperText={touched.contactperson && errors.contactperson}
                                        />
                                        
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                   
                                        <TextField
                                            fullWidth
                                            label="Number of GPs"
                                            {...getFieldProps('gpsnumber')}
                                            error={Boolean(touched.gpsnumber && errors.gpsnumber)}
                                            helperText={touched.gpsnumber && errors.gpsnumber}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Number of nurses"
                                            {...getFieldProps('nursesnumber')}
                                            error={Boolean(touched.nursesnumber && errors.nursesnumber)}
                                            helperText={touched.nursesnumber && errors.nursesnumber}
                                        />
                                    </Stack>


                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl
                                     error={Boolean(touched.manager && errors.manager)}>
                                        <FormControlLabel
                                           
                                            control={
                                                <Switch

                                                    {...getFieldProps('manager')}
                                                />
                                            }
                                            label="Practice Manager"
                                        />
                                         <FormHelperText>{errors.manager}</FormHelperText>
                                    </FormControl>
                                        
                                        <TextField
                                            fullWidth
                                            label="Eircode"
                                            {...getFieldProps('eircode')}
                                            error={Boolean(touched.eircode && errors.eircode)}
                                            helperText={touched.eircode && errors.eircode}
                                        />

                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                                        <FormControl
                                            error={Boolean(touched.mainhospitalid && errors.mainhospitalid)}
                                            fullWidth>
                                            <InputLabel id="demo-simple-select-label">Main Hospital</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                {...getFieldProps('mainhospitalid')}
                                                label="Main Hospital"

                                            >

                                                {hospitals ? hospitals.map((hosp)=> <MenuItem value={hosp.id}>{hosp.name}</MenuItem>) : []}
                                            </Select>
                                            <FormHelperText id="my-helper-text">{errors.mainhospitalid}</FormHelperText>

                                        </FormControl>

                                        <FormControl
                                            error={Boolean(touched.secondhospitalid && errors.secondhospitalid)}
                                            fullWidth>
                                            <InputLabel id="second-hospital-select-label">Second Hospital</InputLabel>

                                            <Select
                                                labelId="second-hospital-select-label"
                                                id="second-hospital-select"
                                                label="Second Hospital"
                                                {...getFieldProps('secondhospitalid')}
                                            >
                                                 {hospitals ? hospitals.map((hosp)=> <MenuItem value={hosp.id}>{hosp.name}</MenuItem>): []}
                                                
                                            </Select>
                                            <FormHelperText id="helper-text-2">{errors.secondhospitalid}</FormHelperText>
                                        </FormControl>





                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                    <FormControl
                                            error={Boolean(touched.laboratoryid && errors.laboratoryid)}
                                            fullWidth>
                                            <InputLabel id="laboratory-select-label">Laboratory</InputLabel>
                                            <Select
                                                labelId="laboratory-select-label"
                                                id="laboratory-select"
                                                label="Laboratory"
                                                {...getFieldProps('laboratoryid')}

                                            >
                                                 {labs ? labs.map((lab)=> <MenuItem value={lab.id}>{lab.name}</MenuItem>) : []}
                                               
                                            </Select>
                                            <FormHelperText id="helper-text-3">{errors.laboratoryid}</FormHelperText>

                                        </FormControl>

                                    <FormControl
                                     error={Boolean(touched.dispensingpractice && errors.dispensingpractice)}>
                                        <FormControlLabel
                                           
                                            control={
                                                <Switch

                                                    {...getFieldProps('dispensingpractice')}
                                                />
                                            }
                                            label="Dispensing practice"
                                        />
                                         <FormHelperText>{errors.dispensingpractice}</FormHelperText>
                                    </FormControl>
                                  
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                           
                                    <FormControl
                                            error={Boolean(touched.placeid && errors.placeid)}
                                            fullWidth>
                                            <InputLabel id="place-select-label">Practice Place</InputLabel>
                                            <Select
                                                labelId="place-select-label"
                                                id="place-select"
                                                label="Practice Place"
                                                {...getFieldProps('placeid')}

                                            >
                                                 {place ? place.map((lab)=> <MenuItem value={lab.id}>{lab.name}</MenuItem>) : []}
                                               
                                            </Select>
                                            <FormHelperText id="helper-text-4">{errors.placeid}</FormHelperText>

                                        </FormControl>
                                       
                                        </Stack>

                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <FormControl
                                        error={Boolean(touched.termsAccepted && errors.termsAccepted)}>
    
                                            <FormControlLabel
                                                value="end"
                                                componentsProps={{error: true}}
                                                control={
                                                    <Checkbox
                                                        {...getFieldProps('termsAccepted')}
                                                    />}
                                                label="I agree to participate in the CARA Network and have read the research agreement"
                                                labelPlacement="end"
                                            />
                                              <FormHelperText>{touched.termsAccepted && errors.termsAccepted}</FormHelperText>
                                    </FormControl>
                                        </Stack>
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}


const mapState = state => ({
user: state.user,
options: state.options,
practice: state.practice
});

const mapDispatch = ({
  practice: { compleatedetails,getHospitals,getLaboratories,getPlaces } }) => ({
    compleatedetails: (payload) => compleatedetails(payload),
    getHospitals: () => getHospitals(),
    getLaboratories: () => getLaboratories(),
    getPlaces: () => getPlaces()
  });

export default connect(mapState, mapDispatch)(practicesetup);

