import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Box } from '@mui/material';
import { merge } from 'lodash';
import { BaseOptionChart } from '../../charts';
import { alpha, useTheme } from '@mui/material/styles';
import { connect } from "react-redux";




  const app = function AppWeeklySales(props) {
   const [model1, setModel1] = useState([]);

   const theme = useTheme();


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setModel1(props.model1.sort((a, b) => a.age_id > b.age_id ? 1 : -1))
  }, [props.model1]);

  console.log("model1series in chart", model1)

  let generateOptions = (cat, variant, theme)=> merge(BaseOptionChart(), {
    colors: [
      variant == "Red" ? theme.palette.secondary.main :  theme.palette.primary.main,
        theme.palette.chart.yellow[0],
        theme.palette.chart.blue[0],
        theme.palette.chart.violet[0],
        theme.palette.chart.green[0],
        theme.palette.chart.red[0]
      ],
    chart: {
      id: "basic-bar",
     
    },
    xaxis: {
      categories: cat
    }
  });
     

      
    return (
        <Card>
        <CardHeader title="Antibiotics by Age" subheader="(+43%) than last year" />
            <ReactApexChart
              options={generateOptions(model1.filter(data=>data.ab_at_consult == props.variant).map(data=>data.age_label), props.variant, theme)}
              series={[
                {
                  name: "Ab",
                  data: model1.filter(data=>data.ab_at_consult ==  props.variant).map(data=>parseFloat(data.percentage).toFixed(2))
                }
              ]}
              type="bar"              
            />
        </Card>
    );
  }


const mapState = state => ({
   model1: state.charts.model1
  });
  
  const mapDispatch = ({ count: { addBy, addByAsync },
  charts: {getModel1}}) => ({
    getModel1: () => getModel1()
  });
  
  export default connect(mapState, mapDispatch)(app);