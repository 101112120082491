import { merge } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, alpha, Typography } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppCurrentVisits(props) {
  const [chartColors, setChartColors] = useState([]);
  const [maxPo, setMaxPo] = useState(0);

  useEffect(() => {
    if (props.patients.gender_categories.length === 1) {
      if (props.patients.gender_categories[0] == "Male")
        setChartColors(["#4f9ea9"]);
      else setChartColors(["#ff8533"]);
    } else {
      setChartColors(["#ff8533", "#4f9ea9"]);
    }
  }, [props.patients.gender_categories]);

  useEffect(() => {
    const grandMax = Math.max(
      ...props.patients.gender_patients,
      ...props.otherPatients.other_gender_patients
    );
    const maxX = grandMax + grandMax * 0.2;
    setMaxPo(maxX);
  }, [
    props.patients.gender_patients,
    props.otherPatients.other_gender_patients,
  ]);

  const mixedGender = [
    {
      data: props.patients.gender_patients,
    },
  ];

  const mixedGenderFilteredP = [
    {
      data: props.otherPatients.other_gender_patients,
    },
  ];

  const chartOptions_FemaleBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Number prescribed: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: chartColors,
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: true,
      },
      chart: {
        brush: {
          enabled: false,
          target: "GenderDonut",
          autoScaleYaxis: false,
          id: "GenderBar",
          group: "Gender",
        },
        type: "area",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            console.log(chartContext, config);
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "lighten",
              value: 0.15,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: props.patients.gender_categories,
      colors: "#708090",
      // position: 'top',
    },
    yaxis: {
      show: false,
      max: maxPo,
    },
    stroke: {
      show: false,
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={0}
        mt={1}
      >
        <Box dir="ltr">
          <ReactApexChart
            type="bar"
            series={mixedGender}
            options={chartOptions_FemaleBar}
            height={280}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Your practice
          </Typography>
        </Box>
        <Box dir="ltr">
          <ReactApexChart
            type="bar"
            series={mixedGenderFilteredP}
            options={chartOptions_FemaleBar}
            height={280}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Filtered practices
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  patients: state.overview.patients,
  otherPatients: state.antibioticscomparisons.patients,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppCurrentVisits);
