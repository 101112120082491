import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid, Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { connect } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const app = function (props) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleClose = () => {
    setExpanded(!expanded);
  };

  const handleClick = (option, filterName, index) => {
    let updatedSelectedOptions = [];
    updatedSelectedOptions = selected.includes(option)
      ? selected.filter((item) => item !== option)
      : [...selected, option];
    setSelected(updatedSelectedOptions);
  };

  const [hospitalareaFilters, setHospitalareaFilters] = React.useState(
    props.filters.healtharea || []
  );
  const [locationareaFilters, setLocationareaFilters] = React.useState(
    props.filters.locationarea || []
  );
  const [patientnumberFilters, setPatientnumbersFilters] = React.useState(
    props.filters.patientnumber || []
  );
  const [gpnumberFilters, setGpnumberFilters] = React.useState(
    props.filters.gps || []
  );

  const areaOptions = [
    "Dublin and North East",
    "Dublin and Midlands",
    "Dublin and South East",
    "South West",
    "Mid West",
    "West and North West",
  ];
  const locationOptions = [
    "Big (inner city)",
    "Suburbs",
    "Small (town)",
    "Mixed urban-rural",
    "Rural",
  ];
  const patientsOptions = [
    "Same number of patients (+/- 10%)",
    "Same number of GMS patients (+/- 10%)",
    "Same number of private patients (+/- 10%)",
  ];
  const gpsOptions = ["1", "2-3", "4-5", ">5"];

  const clearFilters = () => {
    setSelected([]);

    setHospitalareaFilters([]);
    setLocationareaFilters([]);
    setPatientnumbersFilters([]);
    setGpnumberFilters([]);

    props.resetFilters();
    updateFilters();
    //props.getComparisonsAB();
  };

  const handleChange = (event, index) => {
    let {
      target: { value },
      target: { name },
    } = event;
    console.log(typeof index);
    console.log(value);

    let updatedSelectedOptions = [];

    switch (name) {
      case "hospitalareaFilters":
        updatedSelectedOptions = [value];
        setHospitalareaFilters(updatedSelectedOptions);
        props.updateFilter({
          key: "healtharea",
          value: [index + 1],
        });
        break;
      case "locationareaFilters":
        updatedSelectedOptions = [value];
        setLocationareaFilters(updatedSelectedOptions);
        props.updateFilter({
          key: "locationarea",
          value: [index + 1],
        });
        break;
      case "patientnumberFilters":
        updatedSelectedOptions = [value];
        setPatientnumbersFilters(updatedSelectedOptions);
        if (index == 1) {
          props.updateFilter({
            key: "gms",
            value: [["GMS"]],
          });
        } else if (index == 2) {
          props.updateFilter({
            key: "gms",
            value: [["Private"]],
          });
        } else {
          props.updateFilter({
            key: "patientnumber",
            value: updatedSelectedOptions,
          });
        }

        break;
      case "gpnumberFilters":
        updatedSelectedOptions = [value];
        setGpnumberFilters(updatedSelectedOptions);
        props.updateFilter({ key: "gps", value: updatedSelectedOptions });
        break;
    }
  };
  let combinedFilters = [];
  combinedFilters = combinedFilters.concat(
    hospitalareaFilters,
    locationareaFilters,
    patientnumberFilters,
    gpnumberFilters
  );
  let updateFilters = () => {
    props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4({});
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();
    // props.loadData()
    // props.getPractice()
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 170,
        zIndex: 6,
        width: "79%",
        left: "100%-100%/6",
        right: "2%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          padding: "5px 0",
          border: "1px solid rgba(145, 158, 171, 0.24)",
          borderRadius: "8px",
        }} /*elevation={20}*/
      >
        <Accordion
          expanded={expanded}
          onChange={() => {
            console.log("accordion change 2");
            //updateFilters();
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={handleClose}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item md={2}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#22577a", textAlign: "center" }}
                >
                  {" "}
                  Comparison applied:{" "}
                </Typography>
              </Grid>

              <Grid
                item
                md={7.1}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {combinedFilters.length > 0 ? (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {combinedFilters.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "Center",
                      gap: 0.5,
                      backgroundColor: "rgba(145, 158, 171, 0.16)",
                      padding: "5px 8px",
                      borderRadius: "16px",
                    }}
                  >
                    <Typography color="black" style={{ fontSize: "13px" }}>
                      {" "}
                      All practices selected{" "}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item md={2} style={{ alignContent: "start" }}>
                <Button
                  variant="outlined"
                  onClick={(event) => {
                    event.stopPropagation();
                    clearFilters();
                  }}
                >
                  Clear Selection
                </Button>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            onChange={() => {
              console.log("accordion change");
              updateFilters();
            }}
          >
            <FormControl sx={{ m: 1, width: "auto" }}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ color: "#22577A", fontWeight: "bold" }}
              >
                Regional Health Area
              </FormLabel>
              {areaOptions.map((option, index) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Radio
                      checked={hospitalareaFilters.includes(option)}
                      onClick={() => handleClick(option, "hospitalareaFilters")}
                      name="hospitalareaFilters"
                      value={option}
                      onChange={(event) => handleChange(event, index)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>

            <FormControl sx={{ m: 1, width: 200 }}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ color: "#22577A", fontWeight: "bold" }}
              >
                Location
              </FormLabel>
              {locationOptions.map((option, index) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Radio
                      checked={locationareaFilters.includes(option)}
                      onClick={() => handleClick(option, "locationareaFilters")}
                      name="locationareaFilters"
                      value={option}
                      onChange={(event) => handleChange(event, index)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>

            <FormControl sx={{ m: 1, width: 400 }}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ color: "#22577A", fontWeight: "bold" }}
              >
                Number of patients
              </FormLabel>
              {patientsOptions.map((option, index) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Radio
                      checked={patientnumberFilters.includes(option)}
                      onClick={() =>
                        handleClick(option, "patientnumberFilters")
                      }
                      name="patientnumberFilters"
                      value={option}
                      onChange={(event) => handleChange(event, index)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>

            <FormControl sx={{ m: 1, width: 200 }}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ color: "#22577A", fontWeight: "bold" }}
              >
                Number of full-time GPs
              </FormLabel>

              {gpsOptions.map((option, index) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Radio
                      checked={gpnumberFilters.includes(option)}
                      onClick={() => handleClick(option, "gpnumberFilters")}
                      name="gpnumberFilters"
                      value={option}
                      onChange={(event) => handleChange(event, index)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ minWidth: 0 }}
                spacing={6}
              >
                <Item>
                  <Button variant="outlined" onClick={clearFilters}>
                    Clear Selection
                  </Button>
                </Item>
                <Item>
                  <Button variant="outlined" onClick={handleClose}>
                    Close Selection
                  </Button>
                </Item>
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

const mapState = (state) => ({
  filters: state.filters,
});

const mapDispatch = ({
  filters: { updateFilter, resetFilters },
  antibioticscomparisons: {
    getComparisonsAB,
    compare_1,
    compare_2,
    compare_3,
    compare_4,
    compare_5,
    compare_6,
    compare_7,
    pocompare_1,
    pocompare_2,
    pocompare_3,
    pocompare_4,
    pocompare_5,
    pocompare_6,
    pocompare_7,
  },
}) => ({
  updateFilter: (payload) => updateFilter(payload),
  compare_1: () => compare_1(),
  compare_2: () => compare_2(),
  compare_3: () => compare_3(),
  compare_4: (payload) => compare_4(payload),
  compare_5: () => compare_5(),
  compare_6: () => compare_6(),
  compare_7: () => compare_7(),
  pocompare_1: () => pocompare_1(),
  pocompare_2: () => pocompare_2(),
  pocompare_3: () => pocompare_3(),
  pocompare_4: () => pocompare_4(),
  pocompare_5: () => pocompare_5(),
  pocompare_6: () => pocompare_6(),
  pocompare_7: () => pocompare_7(),
  resetFilters: () => resetFilters(),
});

export default connect(mapState, mapDispatch)(app);
