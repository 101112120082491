import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box, Typography, Stack, alpha } from "@mui/material";
import { fNumber, findNextMultipleOf500 } from "../../../utils/formatNumber";
import React, { useState, useEffect } from "react";

//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppLine(props) {
  const [weekdayYaxis, setWeekdayYaxis] = useState(null);
  const [durationYaxis, setDurationYaxis] = useState(null);
  const [yearYaxis, setYearYaxis] = useState(0);

  useEffect(() => {
    var nextMultipleOf500 = findNextMultipleOf500(
      props.time.green_weekday,
      props.time.red_weekday
    );
    setWeekdayYaxis(nextMultipleOf500);

    nextMultipleOf500 = findNextMultipleOf500(
      props.time.green_duration,
      props.time.red_duration
    );
    setDurationYaxis(nextMultipleOf500);
  }, [props.time]);

  useEffect(() => {
    var nextMultipleOf500 = findNextMultipleOf500(
      props.year.green_2019,
      props.year.red_2019
    );
    var temp = findNextMultipleOf500(
      props.year.green_2020,
      props.year.red_2020
    );
    nextMultipleOf500 = Math.max(nextMultipleOf500, temp);
    temp = findNextMultipleOf500(props.year.green_2021, props.year.red_2021);
    nextMultipleOf500 = Math.max(nextMultipleOf500, temp);
    setYearYaxis(nextMultipleOf500);
  }, [props.year]);

  const CHART_DATA_Line = [
    {
      name: "Green " + props.year.now,
      data: props.year.green_2021,
    },
    {
      name: "Red " + props.year.now,
      data: props.year.red_2021,
    },
    {
      name: "Green " + props.year.mid,
      data: props.year.green_2020,
    },
    {
      name: "Red " + props.year.mid,
      data: props.year.red_2020,
    },
    {
      name: "Green " + props.year.last,
      data: props.year.green_2019,
    },
    {
      name: "Red " + props.year.last,
      data: props.year.red_2019,
    },
  ];
  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        title: {
          formatter: (seriesName) => seriesName + ": ",
        },
        formatter: (seriesName) => fNumber(seriesName),
      },
    },
    // colors: ["#26734D", "#cc3311", "#b3e6cc", "#f9c4b8"],
    colors: [
      alpha("#26734D", 1),
      alpha("#cc3311", 1),
      alpha("#26734D", 0.6),
      alpha("#cc3311", 0.6),
      alpha("#26734D", 0.2),
      alpha("#cc3311", 0.2),
    ],
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: "10px",
        colors: ["#000000"],
      },
      background: {
        enabled: false,
        color: "White",
      },
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        const seriesData = w.config.series[seriesIndex].data;
        const labelPoint = seriesData.filter(Boolean).length;
        if (dataPointIndex === labelPoint - 1) {
          return w.config.series[seriesIndex].name;
        } else {
          return "";
        }
      },
      offsetX: -10,
      offsetY: -3,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      min: 0,
      max: yearYaxis,
      forceNiceScale: true,
    },
    legend: {
      show: true,
      position: "bottom",
      offsetY: 10,
      formatter: function (seriesName) {
        return seriesName;
      },
    },
  });

  const CHART_DATA_Week = [
    {
      name: ["green antibiotics"],
      data: props.time.green_weekday,
      color: "#26734D",
    },
    {
      name: ["red antibiotics"],
      data: props.time.red_weekday,
      color: "#cc3311",
    },
  ];
  const chartOptionsWeek = merge(BaseOptionChart(), {
    annotations: {
      yaxis: [
        {
          x: 0,
          borderColor: "#000",
          label: {
            borderColor: "#000",
            style: {
              color: "#fff",
              background: "#000",
            },
          },
        },
      ],
    },
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Percentage of all antibiotic: `,
        },
        formatter: function (val, { seriesIndex, w }) {
          const totalGreen = w.config.series[0].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const totalRed = w.config.series[1].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const total = totalGreen + totalRed;
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "90%",
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -25,
    },
    yaxis: {
      show: false,
      min: 0, // Set a minimum value for the y-axis
      max: weekdayYaxis,
      forceNiceScale: false, // Set forceNiceScale to false
    },
    xaxis: {
      categories: ["Mon", "Tues", "Wed", "Thur", "Fri"],
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
    },
  });

  const CHART_DATA_Duration = [
    {
      name: ["green antibiotic"],
      data: props.time.green_duration,
      color: "#26734D",
    },
    {
      name: ["red antibiotic"],
      data: props.time.red_duration,
      color: "#cc3311",
    },
  ];

  const chartOptionsDuration = merge(BaseOptionChart(), {
    annotations: {
      yaxis: [
        {
          x: 0,
          borderColor: "#000",
          label: {
            borderColor: "#000",
            style: {
              color: "#fff",
              background: "#000",
            },
          },
        },
      ],
    },
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Percentage of all antibiotic: `,
        },
        formatter: function (val, { seriesIndex, w }) {
          const totalGreen = w.config.series[0].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const totalRed = w.config.series[1].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const total = totalGreen + totalRed;
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "90%",
        borderRadiusApplication: "end",
      },
      chart: {
        id: "chart1",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -25,
    },
    xaxis: {
      categories: [
        "Once",
        "2 Days",
        "3 Days",
        "4 Days",
        "5 Days",
        "6 Days",
        ["More", "than", "6 days"],
      ],
      rotate: 0,
    },
    yaxis: {
      show: false,
      min: 0, // Set a minimum value for the y-axis
      max: durationYaxis,
      forceNiceScale: false, // Set forceNiceScale to false
    },
    fill: {
      colors: ["#339966"],
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
      position: "bottom",
      labels: {
        colors: ["#339966", "#990000"],
      },
      brush: {
        target: "chart2",
        enabled: true,
      },
    },
    // strokeColor: ['transparent'],
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Typography variant="h4" color="#22577A">
        Prescriptions of green and red antibiotics over time
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
        mt={5}
      >
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <Typography variant="h6" color="#22577A">
            Per weekday
          </Typography>
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Week}
            options={chartOptionsWeek}
            height={300}
          />
        </Box>
        <Box sx={{ mx: 1, width: 600, height: 400 }} dir="ltr">
          <Typography variant="h6" color="#22577A">
            Duration
          </Typography>
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Duration}
            options={chartOptionsDuration}
            height={340}
          />
        </Box>
      </Stack>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Typography variant="h6" color="#22577A">
          Per year
        </Typography>
        <ReactApexChart
          type="line"
          series={CHART_DATA_Line}
          options={chartOptionsLine}
          height={400}
        />
      </Box>
    </Card>
  );
}
import { connect } from "react-redux";
const mapState = (state) => ({
  time: state.antibiotics.time,
  year: state.antibiotics.year,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
