import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PracticeSetUp from "./PracticeSetUp"
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function RegisterForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [open, setOpen] = useState(false);
  const [eAvailable, setEAvailable] = useState(false);
  const [nAvailable, setNAvailable] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navig = useNavigate()

  const validatePracticeName = async (value) => {
    try {

      if (value && value !== undefined) {
        const payload = {
          username: value,
          check: "username"
        }
        const response = await props.checkUsername(payload);
        return (response);
      }
    } catch (error) {
      throw new Error('Practice name not available');
    }
  };

  const validateEmail = async (value) => {
    try {

      if (value && value !== undefined) {
        const payload = {
          username: value,
          check: "email"
        }
        const response = await props.checkUsername(payload);
        return (response);
      }
    } catch (error) {
      throw new Error('Email not available');
    }
  };



  const RegisterSchema = Yup.object().shape({
    practiceName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Practice name required'),

    healthEmail: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    // .test('healthmail', 'Email must end with @healthmail.ie', (value) => {
    //   return value.endsWith('@healthmail.ie');
    // }),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const formik = useFormik({
    initialValues: {
      practiceName: '',
      healthEmail: '@healthmail.ie',
      password: '',
      confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      // navigate('/dashboard', { replace: true });
      const payload = {
        username: getFieldProps('practiceName').value,
        email: getFieldProps('healthEmail').value,
        password: getFieldProps('password').value
      };
      const responce = await props.register(payload);
      if (responce === "Registered") {
        navig('/interOTP', { state: { param1: 'register', param2: getFieldProps('healthEmail').value } });
      } else {
        throw new Error(responce || "Error");
      }
      //handleOpen(0)
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;


  return (
    <FormikProvider value={formik}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thanks for registering!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            An alphanumeric code has been sent to your email account to verify your identity. Once you get the email, please press continue and follow the instructions.
          </Typography>
          <br />

          <Button onClick={() => {
            handleClose();
            setRegistered(true);
          }}>Continue</Button>
          <Button onClick={() => {
            handleClose();
            setRegistered(true);
          }}>Cancel</Button>
        </Box>

      </Modal>
      {!registered ?
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Practice name"
                onBlurCapture={async () => {
                  try {
                    const message = await validatePracticeName(getFieldProps('practiceName').value);
                    if (message !== "Available") {
                      setNAvailable(true);
                    } else {
                      setNAvailable(false);
                    }
                    if (nAvailable === true) {
                      formik.setFieldError('practiceName', message);
                    }
                  } catch (error) {
                    formik.setFieldError('practiceName', error.message);
                  }
                }}
                {...getFieldProps('practiceName')}
                error={Boolean(touched.practiceName && (nAvailable || errors.practiceName))}
                
                helperText={touched.practiceName && (nAvailable ? 'Practice Name not available' : errors.practiceName)}
              />


            </Stack>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Health email"
              {...getFieldProps('healthEmail')}
              onBlurCapture={async () => {
                try {
                  const message2 = await validateEmail(getFieldProps('healthEmail').value);
                  if (message2 !== "Available") {
                    setEAvailable(true);
                  } else {
                    setEAvailable(false);
                  }
                  if (eAvailable) {
                    formik.setFieldError('healthEmail', message2);
                  }
                } catch (error) {
                  formik.setFieldError('healthEmail', error.message);
                }
              }}
              error={Boolean(touched.healthEmail && (eAvailable || errors.healthEmail))}
              helperText={touched.healthEmail && (eAvailable ? 'Email not available' : errors.healthEmail)}
            />


            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Register
            </LoadingButton>
          </Stack>
        </Form>
        :
        <div />
      }
    </FormikProvider>
  );
}

const mapState = state => ({
});

const mapDispatch = ({
  user: { register, checkUsername } }) => ({
    register: (payload) => register(payload),
    checkUsername: (payload) => checkUsername(payload),
  });

export default connect(mapState, mapDispatch)(RegisterForm);

