import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------


// function AppCurrentVisits(props) {

// const { patients } = props;

// // Define the age group slices
//   const ageSlices = [
//     patients.age.slice(0, 3), // Age groups: <1, 1-6, 7-17
//     patients.age.slice(3, 5), // Age groups: 18-29, 30-49
//     [patients.age[5]], // Age group: 50-69
//     [patients.age[6]], // Age group: 70 and over
//   ];

//   // Calculate the total count for each slice
//   const totalCounts = ageSlices.map((slice) =>
//     Array.isArray(slice) ? slice.reduce((a, b) => a + b, 0) : slice
//   );

//   // Use the total counts in CHART_DATA_AGES
//   const CHART_DATA_AGES = [...totalCounts];
// }

function AppCurrentVisits(props) {
  const CHART_DATA_AGES = props.patients.age_donut
  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      '#3E8397',
      '#5ca8bc',
      '#92c5d3',
      '#c9e2e9',

    ],
    labels: ['Ages 0-17', 'Ages 18-49', 'Ages 50-69', 'Ages 70 and over'],
    // stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false},
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
            marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
      pie: {
      customScale: 1,
      donut: 
        { labels: { show: false },
          // customScale: 0.5
          size: 60 },
          expandOnClick: false,
          },
    chart: {
      brush: {
        enabled: true,
        target: 'AgesBar',
        autoScaleYaxis: false,
        id: 'AgesDonut'
      }},}
  });


  return (
    <Card  sx={{boxShadow: 'none', mt: 4}}>
            <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATA_AGES} options={chartOptions} height={280} />
        </Box>
    </Card>
  );
}
import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients
 });
 
 const mapDispatch = ({ 
  overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
export default connect(mapState, mapDispatch)(AppCurrentVisits);
