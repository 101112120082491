import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import {
  Card,
  CardHeader,
  Box,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import Input from "@mui/material/Input";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

const CHART_DATA_Line = [
  {
    name: "Green Antibiotics prescribed in 2022",
    data: [280, 290, 330, 150, 200, 133, 33, 67, 111, 187, 230, 255],
  },
  {
    name: "Red Antibiotics prescribed in 2022",
    data: [120, 110, 140, 180, 170, 130, 13, 55, 55, 69, 112, 198],
  },
  {
    name: "Green Antibiotics prescribed in 2021",
    data: [380, 190, 530, 50, 280, 163, 3, 78, 141, 157, 239, 355],
  },
  {
    name: "Red Antibiotics prescribed in 2021",
    data: [140, 90, 135, 150, 120, 150, 65, 42, 42, 60, 12, 98],
  },
];

function AppLine(props) {
  const [chartData, setChartData] = useState([]);
  const [dateRange1, setDateRange1] = useState([]);
  const [dateEnd, setDateEnd] = React.useState(props.lastUpdata);

  useEffect(() => {
    const currentDate = new Date(dateEnd);
    const end1 = new Date(currentDate);
    end1.setMonth(end1.getMonth() - 12);

    const data = props.audit3;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dateRange = [];
    const dateRangemonth = [];
    let currentDatePointer = new Date(end1);

    while (currentDatePointer <= currentDate) {
      const monthName = monthNames[currentDatePointer.getMonth()];
      const year = currentDatePointer.getFullYear();
      dateRange.push(`${monthName}`);
      currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
    }
    setDateRange1(dateRange);

    let processedData = [];

    // Create series data from cumulative data

    // Iterate over each yearData in the provided data
    data.forEach((yearData, index) => {
      const cumulativeGreenData = {};
      const cumulativeRedData = {};
      const greenSeries = [];
      const redSeries = [];
      const dateRangeIndex = [];
      const yearRange = [];

      let currentDate2 = new Date(currentDate);
      index == 0
        ? currentDate2.setMonth(currentDate2.getMonth())
        :currentDate2.setMonth(currentDate2.getMonth()-12);
      let end2 = new Date(currentDate2);
      end2.setMonth(end2.getMonth() - 12);
      currentDatePointer = new Date(end2);

      while (currentDatePointer <= currentDate2) {
        const monthName = monthNames[currentDatePointer.getMonth()];
        const year = currentDatePointer.getFullYear();
        if (!yearRange.includes(year)) {
          yearRange.push(year);
        }
        dateRangeIndex.push(`${monthName} ${year}`);
        currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
      }

      Object.entries(yearData).forEach(([year, colors]) => {
        const greenData = colors["Green"];
        const redData = colors["Red"];

        // Accumulate green data
        Object.entries(greenData).forEach(([month, value]) => {
          if (value !== null) {
            const key = `${monthNames[month - 1]} ${year}`;
            cumulativeGreenData[key] = (cumulativeGreenData[key] || 0) + value;
          }
        });

        // Accumulate red data
        Object.entries(redData).forEach(([month, value]) => {
          if (value !== null) {
            const key = `${monthNames[month - 1]} ${year}`;
            cumulativeRedData[key] = (cumulativeRedData[key] || 0) + value;
          }
        });
      });
      dateRangeIndex.forEach((date) => {
        greenSeries.push(cumulativeGreenData[date] || 0);
        redSeries.push(cumulativeRedData[date] || 0);
      });
      processedData.push({
        name: `Green Antibiotics prescribed of ${yearRange[0]} - ${yearRange[1]}`,
        data: greenSeries,
      });
      processedData.push({
        name: `Red Antibiotics prescribed ${yearRange[0]} - ${yearRange[1]}`,
        data: redSeries,
      })
    });

    let furtherProceeded = processedData.flat();

    setChartData(furtherProceeded);
  }, []);

  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => seriesName + ": ",
        },
      },
    },
    colors: ["#26734D", "#cc3311", "#b3e6cc", "#f9c4b8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: dateRange1,
    },
    yaxis: {
      min: 0,
      tickAmount: 7,
      forceNiceScale: true,
    },
    legend: {
      show: false,
      position: "right",
      horizontalAlign: "right",
      floating: false,
      offsetY: -25,
      offsetX: -5,
    },
    // annotations: {
    //   xaxis: [
    //     {
    //       x: 'Sept',
    //       borderColor: '#aa3377',
    //       label: {
    //         style: {
    //           color: '#aa3377',
    //         },
    //         text: 'Audit Start'
    //       }
    //     }
    //   ]
    // }
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <br />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptionsLine}
          height={364}
        />
      </Box>
      <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
        <Input fullWidth multiline rows={8} defaultValue="Your analysis here" />
      </Box>
    </Card>
  );
}
const mapState = (state) => ({
  audit3: state.audit.audit3,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
