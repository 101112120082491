import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography, TextField, Button, Link, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { connect } from "react-redux";
import * as Yup from 'yup';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  p: 5,
}));

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

function EnterEmail(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const param1 = location.state?.param1;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
        check: param1
      }
      await props.resendOTP(payload);
      navigate('/InterOTP', { state: { param1: param1, param2: values.email } })
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = formik;

  return (
    <RootStyle title="CARA | Forgot password">
      <Box >
        <AuthLayout />
      </Box>
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <Typography variant="h4" fontWeight="bold">
            Enter email
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type="email"
                label="@healthmail.ie"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <LoadingButton
                sx={{ marginTop: 3 }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Continue
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>
      </Container>
    </RootStyle>
  );
}

const mapState = state => ({
});

const mapDispatch = ({
  user: { register, verifyOTP, resendOTP } }) => ({
    register: (payload) => register(payload),
    verifyOTP: (payload) => verifyOTP(payload),
    resendOTP: (payload) => resendOTP(payload),
  });

export default connect(mapState, mapDispatch)(EnterEmail);
