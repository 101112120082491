// material
import { Box, Grid, Container, Typography, Paper, Stack, TextField, Button } from '@mui/material';
// components
import Page from '../components/Page';
import {
  ApptypesofABAudit
} from '../components/_dashboard/audit';
import React, { useState } from "react";
import handleDownloadPdf from './exportPdf'
import { connect } from 'react-redux';
import moment from 'moment/moment';
// import { GridPrintExportOptions } from '@mui/x-data-grid';


// ----------------------------------------------------------------------


function DashboardApp({user,  auditDetails:{auditPayload: { tf, goal }}}) {
  const printRef = React.useRef();
  function checkMonth(){
    if(tf ==0){
      return "1 month"
    }
    else if(tf ==1){
      return "3 months"
    }
    else if(tf ==2){
      return "12 months"
    }
    else{
      return "Invalid timeframe"
    }
  }
  return (
    <Page title="Dashboard/Audit" sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth="xl" >
        <div ref={printRef}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h2" color='secondary'>Audit of Antibiotic Prescribing</Typography>
              <br />
              <br />
              <Typography variant="h3" color='secondary'>Practice: {user?.username}</Typography>
              <Typography variant="h6" color='secondary'>Date: {moment().format('MMMM Do YYYY')}</Typography>
              <br />
              <br />
              <Typography variant="h4" color='secondary'>Goal: Reduce the percentage of red antibiotic prescriptions. </Typography>
              <br />
              <Typography variant="h4" color='secondary'>Timeframe: {checkMonth()}. </Typography>
              <br />
            </Grid>
            <Grid item xs={12} id="antibiotics">
              <ApptypesofABAudit />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
        <Button onClick={() => handleDownloadPdf('audit.pdf', printRef)} fullWidth variant="outlined" color='primary'><Typography variant="h4"> Export as PDF</Typography> </Button>

      </Container>
    </Page>
  );
}
const mapState = (state) => ({
  auditDetails: state.audit.auditDetails,
  user: state.user,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(DashboardApp);