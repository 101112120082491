import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------





function AppTypes(props) {
  const CHART_DATA_Types = props.patients.gms_type_donut
  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      '#2C6785',
      '#A5D8D9',
    ],
    labels:  ['GMS', 'Private'],
    // stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false},
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
      pie: {
      customScale: 1,
      donut: 
        { labels: { show: false },
          // customScale: 0.5
          size: 60 },
          expandOnClick: false,
          },
    chart: {
      brush: {
        enabled: true,
        target: 'TypesBar',
        autoScaleYaxis: false,
        id: 'TypesDonut',
        group: 'Types'
      }},}
  });


  return (
    <Card   sx={{boxShadow: 'none', mt: 4}}>
            <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATA_Types} options={chartOptions} height={280} />
        </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients
 });
 
 const mapDispatch = ({ 
  overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
 export default connect(mapState, mapDispatch)(AppTypes);
