import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Box, Card, Typography } from '@mui/material';
import ReactGA from 'react-ga4';
// utils
import { fNumber, fShortenNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  borderColor: theme.palette.primary.main,
  borderWidth: 5,
  height: 300,
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// calculate total number of AB prescriprions of practice and all practices average for 12months prior to last data upload.

function AppWeeklySales(props) {

  const category = "Dashboard "+props.user.dashboard ;
  const dashNo = props.user.dashboard;

  const handleClick = () => {
    // const action = "Antibiotics Click" + dashNo;
    // ReactGA.event({
    //   category: category,
    //   action:action,
    //   label:"Antibiotic Dashboard "+dashNo,
    //   value: parseInt(dashNo)
    // })

    const resistanceElement = document.getElementById('antibiotics');
    if (resistanceElement) {
      resistanceElement.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <RootStyle>
      <Box sx={{ height: 264, border: 1, borderRadius: 2, borderColor: "secondary" }}>
        <br />
        <Typography variant="h5" mt={1.5} color='#aa3377'>{fNumber(props.model.total ? props.model.total : '0')}</Typography>
        <Typography variant="subtitle2">
          Number of antibiotics prescribed</Typography>
        <Typography variant="subtitle2"> in the selected duration.
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle2" >
          All practices average:
        </Typography>
        <Typography variant="h5" color='#aa3377'>{fNumber(props.model.average ? props.model.average : '0')}</Typography>
        <br>
        </br>
        <Button fullWidth style={{ boxShadow:'none', whiteSpace: 'nowrap' }} variant="contained" color='secondary' onClick={handleClick}>See more on antibiotics</Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  model: state.antibiotics.generalAB,
  user: state.user
});

const mapDispatch = ({
  antibiotics: { getGeneralAB } }) => ({
    getGeneralAB: () => getGeneralAB()
  });

export default connect(mapState, mapDispatch)(AppWeeklySales);