import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Button, Box } from '@mui/material';
// utils
import { fShortenNumber, fNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  height: 250,
  display: "flex", 
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// calculate total number of active patients (visited practice at least once in last year)
const TOTAL = '8,156';
const AGE = '47';

function AppWeeklySales(props) {
  return (
    <RootStyle>
    <Box sx={{ height: 208, border: 1, borderRadius: 2, borderColor: "secondary" }}>
      <br />
      <Typography variant="h5" color='#AA3377'>{fNumber(props.prescriptions.active_patients)}</Typography>
      <Typography variant="subtitle2" >
      Number of active patients.
      </Typography>
      <br />
      <Typography variant="subtitle2" >Average age of patients:</Typography>
      <Typography variant="h5" color='#AA3377'>{fNumber(props.prescriptions.average_age_patients)}</Typography>
      <br />
      <Button fullWidth style={{ whiteSpace: 'nowrap' }} variant="contained" color='secondary' href='#demographics'>See more on patients</Button>
    </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  prescriptions: state.overview.prescriptions
 });
 
 const mapDispatch = ({ 
  overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
 export default connect(mapState, mapDispatch)(AppWeeklySales);
