import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import {
  Box,
  Card,
  Stack,
  CardHeader,
  Typography,
  Button,
  alpha,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import React, { useEffect, useState } from "react";

// ----------------------------------------------------------------------

function AppConversionRates(props) {
  const [prescXaxis, setPrescXaxis] = useState(0);

  const CHART_DATA_GREEN = [
    {
      name: ["Your practice"],
      data: props.prescriptions.week_days,
      color: "#3E8397",
    },
    {
      name: ["Filtered practices"],
      data: props.otherprescriptions.other_week_days,
      color: alpha("#3E8397", 0.5),
    },
  ];

  useEffect(() => {
    const data1 = props.prescriptions.week_days;
    const data2 = props.otherprescriptions.other_week_days;
    const maxY1 = Math.max(...data1?.map((item) => item));
    const maxY2 = Math.max(...data2?.map((item) => item));
    const grandMax = Math.max(maxY1, maxY2);
    const maxX = grandMax + grandMax * 0.2;
    setPrescXaxis(maxX);
  }, [props.prescriptions.types]);

  const chartOptions_GREEN = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return percent.toFixed(0) + "%";
        },
        title: {
          formatter: (seriesName) =>
            `Percentage of all antibiotic prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    },
    yaxis: {
      show: false,
      max: prescXaxis,
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
      customLegendItems: ["Your practice", "Filtered practices"],
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA_GREEN}
          options={chartOptions_GREEN}
          height={480}
        />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  prescriptions: state.overview.prescriptions,
  otherprescriptions: state.antibioticscomparisons.prescriptions,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
