import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

//CARA logo colours
const PRIMARY = {
  lighter: '#c8eae9',
  light: '#91d4d3',
  main: '#67c4c3',
  dark: '#399391',
  darker: '#245c5b',
  contrastText: '#fff'
};
//CARA dark colour
const SECONDARY = {
  lighter: '#d7e8f4',
  light: '#74b0d8',
  main: '#22577a',
  dark: '#1c4763',
  darker: '#112b3c',
  contrastText: '#fff'
};
//CARA Accent colour
const ACCENT = {
  lighter: '#edc4dc',
  light: '#d062a0',
  main: '#aa3377',
  dark: '#892960',
  darker: '#621d44',
  contrastText: '#fff'
};
//female colour
const FEMALE = {
  light: '#ffb380',
  main: '#FF8533',
  dark: '#e65c00',
  contrastText: '#fff'
};
//male colour
const MALE = {
  light: '#74b5be',
  main: '#4F9EA9',
  dark: '#397279',
  contrastText: '#fff'
};
//green antibiotics colour
const GREENAB = {
  light: '#39ac73',
  main: '#26734D',
  dark: '#133926',
  contrastText: '#fff'
};
//red antibiotics colour
const REDAB = {
  light: '#f2765a',
  main: '#EC3B13',
  dark: '#a5290d',
  contrastText: '#fff'
};
//GMS colour
const GMS = {
  light: '#4095bf',
  main: '#2C6785',
  dark: '#193c4d',
  contrastText: '#fff'
};
//DVS colour
const DVS = {
  light: '#5db8bb',
  main: '#3C8D90',
  dark: '#26585a',
  contrastText: '#fff'
};
//private colour
const PRIVATE = {
  light: '#93d0d2',
  main: '#60B9BC',
  dark: '#3c8d90',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  accent: createGradient(ACCENT.light, ACCENT.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  accent: { ...ACCENT },
  female: { ...FEMALE},
  male: { ...MALE},
  gms: { ...GMS},
  dvs: { ...DVS},
  private: { ...PRIVATE},
  redAB: { ...REDAB},
  greenAB: { ...GREENAB},
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
