

export const session = {
    state: {
        tte: null,
        timerReference: null,
        showAlert: false
    },
    
    reducers: {
        updateTimeToExpire(state, payload) {
            return{
              ...state, 
             tte: payload
            }
          }, 
          updateShowAlert(state, payload) {
            return{
              ...state, 
              showAlert: payload
            }
          },          
          updateTimerReference(state, payload){
            return{
              ...state, 
             timerReference: payload 
            }
          }
    },
    effects: (dispatch) => ({
     
    })
  };