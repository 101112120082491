import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader, Stack, Typography, alpha } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppPracticePrescriptions(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const CHART_DATA_Prescriptions = [
    {
      data: props.prescriptions.types?.sort((a, b) => b - a),
    },
  ];
  const CHART_DATA_OtherPrescriptions = [
    {
      data: props.otherprescriptions.othertypes?.sort((a, b) => b - a),
    },
  ];

  const [prescXaxis, setPrescXaxis] = useState(0);
  const [otherPrescXaxis, setOtherPrescXaxis] = useState(0);
  useEffect(() => {
    if (props.prescriptions.types && props.prescriptions.types !== undefined) {
      // If data has changed, trigger graph refresh
      setRefreshGraph((prev) => !prev);
    }
  }, [props.prescriptions.types]);

  useEffect(() => {
    const data = props.prescriptions.types;
    const maxY = Math.max(...data.map((item) => item.y));
    const maxX = maxY + maxY * 0.2;
    setPrescXaxis(maxX);
  }, [props.prescriptions.types]);

  useEffect(() => {
    const data = props.otherprescriptions?.othertypes;
    const maxY = Math.max(...data.map((item) => item.y));
    const maxX = maxY + maxY * 0.2;
    setOtherPrescXaxis(maxX);
  }, [props.otherprescriptions.othertypes]);
  const allData = [].concat(
    ...CHART_DATA_Prescriptions.map((obj) => obj.data),
    ...CHART_DATA_OtherPrescriptions.map((obj) => obj.data)
  );

  // Find the maximum value from the combined data
  const maxOverall = Math.max(...allData);

  const chartOptions_Prescriptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (val) {
          const total = props.prescriptions.types.reduce(
            (acc, cur) => acc + cur.y,
            0
          );
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        title: {
          formatter: (seriesName) => `Percentage of all prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: "#3E8397",
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetX: 55,
    },
    xaxis: {
      // categories: [
      //   'Diuretics',
      //   'Beta Blocking Agents',
      //   'Antipsoriatics',
      //   'Antihistamines',
      //   'Antiprotozoals',
      //   'Antibiotics',
      //   'Dopamine agonists',
      //   'Urologicals',
      //   'Opthalmologicals',
      //   ['Antiemetics and', 'Antinauseants'],
      // ],
      categories: props.prescriptions.types_categories
        ? props.prescriptions.types_categories
        : [],
      show: true,
      min: 0,
      max: prescXaxis,
      tickAmount: 5,
      labels: {
        show: false,
        formatter: function (val, opt) {
          const label_value = fNumber(val);
          return label_value;
        },
        style: {
          colors: "#808080",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#22577A",
        },
      },
    },
  });

  const chartOptions_OtherPrescriptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const total = w.config.series[seriesIndex].data.reduce(
            (acc, curr) => acc + curr.y,
            0
          );
          const percentage = ((value / total) * 100).toFixed(0);

          return percentage + "%";
        },
        title: {
          formatter: (seriesName) => `Percentage of all prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: [alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      style: {
        colors: ["#708090"],
      },
      offsetX: 55,
    },
    xaxis: {
      enabled: false,
      categories: props.prescriptions.othertypes_categories
        ? props.prescriptions.othertypes_categories
        : [],
      min: 0,
      max: otherPrescXaxis,
      tickAmount: 5,
      labels: {
        show: false,
        formatter: function (val, opt) {
          const label_value = fNumber(val);
          return label_value;
        },
        style: {
          colors: "#808080",
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: "#22577A",
        },
      },
    },
    stroke: {
      show: false,
    },
  });

  const chartOptions_Middle = merge(BaseOptionChart(), {
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#ffffff"],
      },
      position: "top",
    },
    xaxis: {
      categories: [
        "Diuretics",
        "Beta Blocking Agents",
        "Antipsoriatics",
        "Antihistamines",
        "Antiprotozoals",
        "Antibiotics",
        "Dopamine agonists",
        "Urologicals",
        "Opthalmologicals",
        ["Antiemetics and", "Antinauseants"],
      ],
      labels: {
        show: false,
        style: {
          colors: "#808080",
        },
        align: "center", // Center-align the labels
      },
      // position: 'top',
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
        align: "center", // Center-align the labels
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4, height: 600 }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
        mt={1}
      >
        <Box dir="ltr" width="40%">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_Prescriptions}
            options={chartOptions_Prescriptions}
            height={500}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Your practice
          </Typography>
        </Box>
        {/* <Box dir="ltr" width="10%"> */}
        {/* This invisible chart creates the space */}
        {/* <ReactApexChart type="bar" series={[]} options={chartOptions_Middle} height={490} width={150}/> */}
        {/* </Box> */}
        <Box dir="ltr" width="40%">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_OtherPrescriptions}
            options={chartOptions_OtherPrescriptions}
            height={500}
            key={refreshGraph}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Filtered practices
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
import { useEffect, useState } from "react";
const mapState = (state) => ({
  prescriptions: state.overview.prescriptions,
  otherprescriptions: state.antibioticscomparisons.prescriptions,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppPracticePrescriptions);
