
import {count} from './test';
import {user} from './user';
import {session} from './session';
import {practice} from './practice';
import {options} from './options';
import {charts} from './charts';
import {audit} from './audit';
import {filters} from './filters';
import {antibiotics} from './antibiotics';
import {overview} from './practiceOverview';
import {antibioticscomparisons} from './antibioticscomparisons';

export default {
    count: count,
    user: user, 
    session: session,
    practice: practice,
    options: options,
    charts: charts,
    audit: audit,
    filters: filters,
    antibiotics: antibiotics,
    overview: overview,
    antibioticscomparisons: antibioticscomparisons,
}
