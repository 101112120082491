import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------



function AppAgesBar(props) {
  const CHART_DATAAges = [{
    data: props.patients.age
  }]
  


  const chartOptions_AgesBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Number of patients: `
        }
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props.patients.age_categories
    },
    colors: [
      '#3E8397',
      '#3E8397',
      '#3E8397',
      '#5ca8bc',
      '#5ca8bc',
      '#92c5d3',
      '#c9e2e9',
    ],
    plotOptions: {
      bar:
      {
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
        columnWidth: '60%',
        distributed: true,
      },
      chart: {
        brush: {
          enabled: false,
          target: 'AgesDonut',
          autoScaleYaxis: false,
          id: 'AgesBar',
          group: 'Ages'
        },
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      formatter: function (val, opt) {
      const label_value = fNumber(val)
      return label_value
    },
      enabled: true,
      style: {
        colors: ['#708090']
      },
      offsetY: -20
    },
    yaxis: {
      show: false,
    },
    stroke: {
      show: false,
    },
  });


  return (
    <Card sx={{ boxShadow: 'none', mt: 4 }}>
      <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATAAges} options={chartOptions_AgesBar} height={280} />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients
});

const mapDispatch = ({
  overview: { loadData } }) => ({
    loadData: () => loadData()
  });

export default connect(mapState, mapDispatch)(AppAgesBar);
