import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Box, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  borderColor: '#22577A',
  borderWidth: 5,
  textAlign: 'center',
  padding: theme.spacing(2, 3),
  color: '#22577A',
  backgroundColor: '#ffffff',
  height: "auto",
  display: "flex", 
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// calculate total running number of consultations resulting in an AB and busiest month
const TOTAL = '5,306';
const MONTH = "March"

function AppWeeklySales() {
  return (
    <RootStyle>
    <Box sx={{ borderColor: '#22577A', borderWidth: 5, borderRadius: '16%' }}>
      <Typography variant="h5">{(TOTAL)}</Typography>
      <Typography variant="subtitle2" >
       Total number of consultations resulting in a 
       prescriptions for an antibiotic since January 1st.
      </Typography>
      <Typography variant="subtitle2" >
       Busiest Month:
      </Typography>
      <Typography variant="h5">{(MONTH)}</Typography>
      </Box>
    </RootStyle>
  );
}
import { connect } from "react-redux";
const mapState = state => ({
  model: state.antibiotics.generalAB
 });
 
 const mapDispatch = ({ 
  antibiotics: {getGeneralAB}}) => ({
    getGeneralAB: () => getGeneralAB()
 });
 
 export default connect(mapState, mapDispatch)(AppWeeklySales);