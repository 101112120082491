
import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import { postData, getData } from './auxilarFunctions';
import config from '../config.json';



// count model
export const options = {
    state: {
        hospitals: [],
        places: [],
        laboratories: [],
        board: null
    },

    reducers: {
        updateHospitals(state, payload) {
            return {
                ...state,
                hospitals: payload
            }
        },
        updatePlaces(state, payload) {
            return {
                ...state,
                places: payload
            }
        },
        updateLabs(state, payload) {
            return {
                ...state,
                laboratories: payload
            }
        },
        updateBoard(state, payload) {
            return {
                ...state,
                board: payload
            }
        },

    },
    effects: (dispatch) => ({

        async getHospitals(payload, state) {
            return getData(config.url + '/practice/hospitals', state.user.access_token)
                .then(data => {
                    return this.updateHospitals(data)
                });
        },
        async getPlaces(payload, state) {
            return getData('https://cara.khired.pk/api/practice/places', state.user.access_token)
                .then(data => {
                    return this.updatePlaces(data)
                });
        },
        async getLabs(payload, state) {
            return getData('https://cara.khired.pk/api/practice/laboratories', state.user.access_token)
                .then(data => {
                    return this.updateLabs(data)
                });
        },
        async changeBoard(payload, state) {
            return this.updateBoard(payload);
        },
    })
};