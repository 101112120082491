import { Icon } from "@iconify/react";
// material
import { alpha, styled } from "@mui/material/styles";
import { Button, Card, Typography, Box } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 2),
  color: "#22577A",
  borderColor: "#000000",
  height: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

function AppNewUsers(props) {
  return (
    <RootStyle>
      <Box
        sx={{
          height: 250,
          border: 1,
          borderRadius: 2,
          borderColor: "secondary",
          position: "relative",
        }}
      >
        <br />
        <Typography variant="h5" mt={1.5} color="#aa3377">
          {props.generalAB.perc_12_month + "%"}
        </Typography>
        <Typography variant="subtitle2">
          of your patients received an antibiotic
        </Typography>
        <Typography variant="subtitle2">
          {" "}
          at least once in the previous 12 months.
        </Typography>
        <br />
        <Typography variant="subtitle2">Filtered practices average:</Typography>
        <Typography variant="h5" color="#aa3377">
          {props.generalABcomparison.otherperc_12_month + "%"}
        </Typography>

        <Button
          fullWidth
          style={{
            whiteSpace: "nowrap",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
          variant="contained"
          color="secondary"
          href="#duration"
        >
          See more on duration
        </Button>
      </Box>
    </RootStyle>
  );
}
import { connect } from "react-redux";
const mapState = (state) => ({
  generalAB: state.antibiotics.generalAB,
  generalABcomparison: state.antibioticscomparisons.generalABcomparison,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppNewUsers);
