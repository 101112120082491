import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Box } from '@mui/material';
import { merge } from 'lodash';
import { BaseOptionChart } from '../../charts';
import { alpha, useTheme } from '@mui/material/styles';
import { connect } from "react-redux";


  const app = function AppWeeklySales(props) {
   const [series, setSeries] = useState([]);
   const theme = useTheme();
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setSeries([{
      name: "Red",
      data: props.model1.filter(data=>data.ab_at_consult ==  "Red").map(data=>parseFloat(data.percentage).toFixed(2))
    },
    {
      name: "Green",
      data: props.model1.filter(data=>data.ab_at_consult ==  "Green").map(data=>parseFloat(data.percentage).toFixed(2))
    },
   
  ])
  }, [props]);

    let options =  merge(BaseOptionChart(), {
        colors: [
            theme.palette.secondary.main,
            theme.palette.primary.main,
            theme.palette.chart.yellow[0],
            theme.palette.chart.blue[0],
            theme.palette.chart.violet[0],
            theme.palette.chart.green[0],
            theme.palette.chart.red[0]
          ],
        chart: {
          id: "basic-bar",
         
        },
        xaxis: {
          categories: props.model1.filter(data=>data.ab_at_consult == props.variant).map(data=>data.age_label)
        }
      });
      
     

      
    return (
        <Card>
        <CardHeader title="Antibiotics by Age" subheader="(+43%) than last year" />
            <ReactApexChart
              options={options}
              series={series}
              type="bar"              
            />
        </Card>
    );
  }


const mapState = state => ({
   model1: state.charts.model1
  });
  
  const mapDispatch = ({ count: { addBy, addByAsync },
  charts: {getModel1}}) => ({
    getModel1: () => getModel1()
  });
  
  export default connect(mapState, mapDispatch)(app);