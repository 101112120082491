import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Button, Box } from '@mui/material';
// utils
import { fNumber, fShortenNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  height: 250,
  display: "flex", 
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------


function AppWeeklySales(props) {
  return (
    <RootStyle>
    <Box sx={{ height: 210, border: 1, borderRadius: 2, borderColor: "secondary" }}>
      <br />
      <Typography variant="h5" color='#AA3377'>{fNumber(props.prescriptions.total)}</Typography>
      <Typography variant="subtitle2" >
       Number of prescriptions.
      </Typography>
      <br />
      <Typography variant="subtitle2" >Filtered practices average:</Typography>
      <Typography variant="h5" color='#AA3377'>{fNumber(props.otherprescriptions.othertotal)}</Typography> 
      <br />
      <Button fullWidth style={{ whiteSpace: 'nowrap' }} variant="contained" color='secondary' href='#prescriptions'>See more on prescriptions</Button>     
    </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  prescriptions: state.overview.prescriptions,
  otherprescriptions: state.antibioticscomparisons.prescriptions
 });
 
 const mapDispatch = ({ 
  overview: {loadData},
  antibioticscomparisons: { getComparisonsAB },
}) => ({
    loadData: () => loadData(),
    getComparisonsAB: () => getComparisonsAB(),
 });
 
 export default connect(mapState, mapDispatch)(AppWeeklySales);

