import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from 'react-router-dom';
import { Card, Stack, Container, Typography, Box } from "@mui/material";
import Page from "../components/Page";
import Logo from '../components/Logo';
import { useNavigate, useLocation } from "react-router-dom";
import RegistrationDetailForm from "./RegisDetailForm";
import logo from "../logo.jpg";
import rightarrow from "../rightarrow.png";
import AuthLayout from '../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 900,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 1200,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(15, 0),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

export default function RegistrationDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();

  // Accessing parameters from state
  const param1 = location.state?.param1;

  return (
    <RootStyle title="CARA | Set Practice">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Container style={{ marginTop: 50 }} maxWidth="xl">
        <ContentStyle sx={{ display: "flex", flexDirection: "row" }}>
          <Stack alignItems="center" mr={5} >
            <Typography sx={{ color: '#68C4C3', fontSize: 20, fontWeight: 700, }} mt={8}>
              Thank you for verifying your email..
            </Typography>
            <Typography sx={{ color: "#68C4C3", fontSize: 17, fontWeight: 500 }} mt={2}>
              Please complete the registration by
            </Typography>
            <Typography sx={{ color: "#68C4C3", fontSize: 17, fontWeight: 500 }} mt={1}>
              filling in your details.
            </Typography>
          </Stack>
          <RegistrationDetailForm email={param1} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
