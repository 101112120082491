import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { alpha, useTheme } from '@mui/material/styles';
import { connect } from "react-redux";

// ----------------------------------------------------------------------


const app = function AppConversionRates(props) {
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '50%', borderRadius: 2 }
    },
    xaxis: {
      categories:props.model2.filter(data=>data.label).map(data=>data.label)
    }
  });

  const CHART_DATA = [{ data: props.model2.filter(data=>data.label).map(data=>parseFloat(data.percentage).toFixed(2)) }];

  return (
    <Card>
      <CardHeader title="Types of Antibiotics" subheader="Since January 2022" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}


const mapState = state => ({
  model2: state.charts.model2
 });
 
 const mapDispatch = ({ count: { addBy, addByAsync },
 charts: {getModel1}}) => ({
   getModel1: () => getModel1()
 });
 
 export default connect(mapState, mapDispatch)(app);