import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

import { connect } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 20000;

const app = function AppWeeklySales(props) {
  return (
    <RootStyle>
    
      <IconWrapperStyle>
        <Icon icon={checkOutlined} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
       Practices Across Ireland
      </Typography>
    </RootStyle>
  );
}



const mapState = state => ({
  count: state.count,
  user: state.user
});

const mapDispatch = ({ count: { addBy, addByAsync },
user: {login, logout, refreshToken}}) => ({
  addByOne: () => addBy(1),
  addByOneAsync: () => addByAsync(1),
  login:() => login(),
  logout: ()=> logout(),
  refreshToken:()=>refreshToken()
});

export default connect(mapState, mapDispatch)(app);
