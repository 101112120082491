
export function checkYearDifference(tf, lastUpload) {
    const lastUploadDate = new Date(lastUpload); // Convert lastUpload to a Date object

    let previousDate;
    if (tf == 0) {
      previousDate = new Date(lastUploadDate.setMonth(lastUploadDate.getMonth() - 1)); // Subtract 1 month
    } else if (tf == 1) {
      previousDate = new Date(lastUploadDate.setMonth(lastUploadDate.getMonth() - 3)); // Subtract 3 months
    } else if (tf == 2) {
      previousDate = new Date(lastUploadDate.setFullYear(lastUploadDate.getFullYear() - 1)); // Subtract 12 months (1 year)
    } else {
      return "Invalid timeframe";
    }
  
    // Extract the years from both dates
    const lastUploadYear = new Date(lastUpload).getFullYear();
    let previousYear = new Date(new Date(lastUpload).setFullYear(lastUploadYear - 1));
    previousYear = new Date(previousYear).getFullYear();
  
    return {
      lastUploadYear,
      previousYear
    };
  }