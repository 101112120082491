import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Button, Box } from '@mui/material';
// utils
import { fShortenNumber, fNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  height: 250,
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// calculate total running number of consultations and busiest month
const TOTAL = '15,306';
const MONTH = "March"

function AppWeeklySales(props) {
  return (
    <RootStyle>
      <Box sx={{ height: 208, border: 1, borderRadius: 2, borderColor: "secondary" }}>
        <br />
        <Typography variant="h5" color='#AA3377'>{fNumber(props.prescriptions.consultations)}</Typography>
        <Typography variant="subtitle2" >
          Number of consultations.
        </Typography>
        <br />
        <Typography variant="subtitle2" >
          Busiest month:
        </Typography>
        <Typography variant="h5" color='#AA3377'>{(props.prescriptions.busy_month)}</Typography>
        <br />
        <Button fullWidth style={{ whiteSpace: 'nowrap'}} variant="contained" color='secondary' href='#consultations'>See more on consultations</Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  prescriptions: state.overview.prescriptions
});

const mapDispatch = ({
  overview: { loadData }
}) => ({
  loadData: () => loadData(),
});

export default connect(mapState, mapDispatch)(AppWeeklySales);