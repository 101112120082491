import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function findNextMultipleOf500(arr1, arr2) {
  if (arr1 && arr2) {
    const maxValue = Math.max(Math.max(...arr1), Math.max(...arr2));
    return Math.ceil(maxValue / 500) * 500;
  }
}

export function findNextMultipleOf1000(value) {
  if (value !== undefined && value !== null) {
    return Math.ceil(value / 1000) * 1000;
  }
}

export function addToMap(array, index, consolidatedMap) {
  array.forEach(item => {
    if (!consolidatedMap.has(item.x)) {
      consolidatedMap.set(item.x, [0, 0]);
    }
    const existingData = consolidatedMap.get(item.x);
    existingData[index] = item.y;
    consolidatedMap.set(item.x, existingData);
  });

  return consolidatedMap;
}

export function checkMonth(tf){
  if(tf ==0){
    return "1 month"
  }
  else if(tf ==1){
    return "3 months"
  }
  else if(tf ==2){
    return "12 months"
  }
  else{
    return "Invalid timeframe"
  }
}