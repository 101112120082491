import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  Stack,
  Box,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import React, { useState, useEffect } from "react";
//
import { BaseOptionChart } from "../../charts";
import Input from "@mui/material/Input";
import { connect } from "react-redux";
import { checkYearDifference } from "src/utils/utils";
// ----------------------------------------------------------------------

function AppCurrentVisits(props) {

  const yesrs = checkYearDifference(props.auditDetails.auditPayload.tf,props.lastUpdata);
  const CHART_DATA_Consultations = [
    {
      name: [yesrs.lastUploadYear],
      data: [props.audit1.all_consultations_1, props.audit1.ab_consultations_1],
      color: "#3E8397",
    },
    {
      name: [yesrs.previousYear],
      data: [props.audit1.all_consultations_2, props.audit1.ab_consultations_2],
      color: "#6eb1c4",
    },
  ];

  const CHART_DATA_Prescriptions = [
    {
      name: [yesrs.lastUploadYear],
      data: [props.audit1.all_prescriptions_1, props.audit1.ab_prescriptions_1],
      color: "#3E8397",
    },
    {
      name: [yesrs.previousYear],
      data: [props.audit1.all_prescriptions_2, props.audit1.ab_prescriptions_2],
      color: "#6eb1c4",
    },
  ];

  const getMaxYAxisValue = (options) => {
    let maxValue = 0;
    options.forEach((series) => {
      const sum = series.data.reduce((total, value) => total + value, 0);
      if (sum > maxValue) {
        maxValue = sum;
      }
    });
    // Multiply by 1.3 to set it as 1.3 times the maximum value
    return maxValue * 1.3;
  };

  const maxConsultPrescribeYAxisValue = getMaxYAxisValue(
    CHART_DATA_Consultations
  );
  const maxPrescribeYAxisValue = getMaxYAxisValue(CHART_DATA_Prescriptions);

  const chartOptions_ConsultPrescribe = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return percent.toFixed(0) + "%";
        },
        title: {
          formatter: (seriesName) => `Percentage of total: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: false,
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: [
        "Consultations",
        ["Consultations resulting", "in an antibiotic prescription"],
      ],
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxConsultPrescribeYAxisValue,
    },
    stroke: {
      show: false,
    },
  });

  const chartOptions_Prescribe = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return percent.toFixed(0) + "%";
        },
        title: {
          formatter: (seriesName) => `Percentage of total: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: false,
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: ["Prescriptions", "Antibiotic prescriptions"],
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxPrescribeYAxisValue,
    },
    stroke: {
      show: false,
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <br />
      <br />
      <br />
      <Typography variant="h4" color="#22577A">
        Antibiotic consultations and prescriptions
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          <Grid item sm={12} md={6} lg={4}>
            <Box sx={{ mx: 1 }} dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_Consultations}
                options={chartOptions_ConsultPrescribe}
                height={480}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Box sx={{ mx: 1 }} dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_Prescriptions}
                options={chartOptions_Prescribe}
                height={480}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
        <Input fullWidth multiline rows={8} defaultValue="Your analysis here" />
      </Box>
    </Card>
  );
}

const mapState = (state) => ({
  audit1: state.audit.audit1,
  auditDetails: state.audit.auditDetails,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppCurrentVisits);
