import { styled } from '@mui/material/styles';
import { Card,  Container,  } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { useNavigate, useLocation } from "react-router-dom";
import EnterNewPassword from './EnterNewPassword';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0)
}));

// ----------------------------------------------------------------------

export default function EnterNewEmail(props) {
  const navigate=useNavigate();
  const location = useLocation();

  // Accessing parameters from state
  const param1 = location.state?.param1;

  return (
    <RootStyle title="CARA | Forgot password">
      <AuthLayout />
      <Container style={{}} maxWidth="sm">
        <ContentStyle>
          <EnterNewPassword email={param1}/>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
