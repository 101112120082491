import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { useRef, useState, useEffect } from "react";
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Link, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { connect } from "react-redux";
import {
  AppABPiesComparison,
} from "../components/_dashboard/comparison";
import {
  ApptypesofABAudit
} from '../components/_dashboard/audit';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

function DashButton(props) {

  const [color, setColor] = useState(null);
  const [color2, setColor2] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [dateEnd, setDateEnd] = useState(props.lastUpdata);
  const [tf, setTf] = useState("0");

  const board = props.board;

  useEffect(async () => {
    const start1 = new Date(dateEnd);
    const end1 = new Date(start1);
    end1.setMonth(end1.getMonth() - (tf === "0" ? 1 : tf === "1" ? 3 : 12));

    const start2 = new Date(start1);
    start2.setMonth(start2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
    const end2 = new Date(end1);
    end2.setMonth(end2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));

    const payload = {
      start1: start1,
      end1: end1,
      start2: start2,
      end2: end2,
    };
    props.compare_4({ payload });
    props.promice_3_4({ payload });
    props.audit_2({ payload });
  }, []);

  useEffect(() => {
    const totalYear1 = props.ab.practice_green + props.ab.practice_red;
    const year1GreenPercentage = (props.ab.practice_green / totalYear1) * 100;
    const year1RedPercentage = (props.ab.practice_red / totalYear1) * 100;

    // Calculate percentages for year 2
    const totalYear2 = props.ab.otherpractice_green + props.ab.otherpractice_red;
    const year2GreenPercentage = (props.ab.otherpractice_green / totalYear2) * 100;
    const year2RedPercentage = (props.ab.otherpractice_red / totalYear2) * 100;

    if (year1GreenPercentage > year2GreenPercentage) {
      setColor(true)
    } else if (year1RedPercentage > year2RedPercentage) {
      setColor(false)
    } else {
      // Handle equal percentages or other cases if needed
      setColor(null);
    }
  }, [props.ab]);

  useEffect(() => {
    const totalYear1 = props.audit2.year1_green + props.audit2.year1_red;
    const year1GreenPercentage = (props.audit2.year1_green / totalYear1) * 100;
    const year1RedPercentage = (props.audit2.year1_red / totalYear1) * 100;

    // Calculate percentages for year 2
    const totalYear2 = props.audit2.year2_green + props.audit2.year2_red;
    const year2GreenPercentage = (props.audit2.year2_green / totalYear2) * 100;
    const year2RedPercentage = (props.audit2.year2_red / totalYear2) * 100;

    if (year1GreenPercentage > year2GreenPercentage) {
      setColor2(true)
    } else if (year1RedPercentage > year2RedPercentage) {
      setColor2(false)
    } else {
      // Handle equal percentages or other cases if needed
      setColor2(null);
    }
  }, [props.audit2]);


  return (
    <RootStyle title="CARA | Dashboard">
      {
        board == 2 ?
          <Container>
            <MotionContainer initial="initial" open>
              <Card sx={{ boxShadow: "none", mt: 4, margin: 'auto', textAlign: 'center' }}>
                {/* <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Dashboard 2!
              </Typography>
            </motion.div> */}

                <AppABPiesComparison filterText="All Practices Average" />
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 4, fontWeight: 'bold' }} paragraph>
                  Last month <span style={{ fontWeight: 'bold' }}>your practice</span> prescribed relatively{' '}
                  <span style={{ color: color ? "red" : (color == null ? "black" : 'red') }} >{color ? "less red" : (color == null ? "same" : 'more red')}</span> antibiotics{' '}
                  <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>compared  to other practices</span>
                </Typography>
                <br />
                <br />
                <Button to="/dashboard/antibiotics_1" size="large" variant="contained" component={RouterLink}>
                  Click Here
                </Button>
                <br />
                <br />
                <motion.div variants={varBounceIn}>
                  <Typography variant="subtitle2" paragraph>
                    * Red antibiotic should be avoided in first line in general practice ({' '}
                    <Link
                      href="https://antibioticprescribing.ie/"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="always"
                      color="primary"
                    >
                      antibioticprescribing.ie
                    </Link>
                    {' '})
                  </Typography>
                </motion.div>
              </Card>
            </MotionContainer>
          </Container>
          :
          <Container>
            <MotionContainer initial="initial" open>
              <Card sx={{ boxShadow: "none", mt: 4, margin: 'auto', textAlign: 'center' }}>
                {/* <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Dashboard 2!
              </Typography>
            </motion.div> */}

                <ApptypesofABAudit filterBox={false} filterText={true} />
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 4, fontWeight: 'bold' }} paragraph>
                  <span style={{ fontWeight: 'bold' }}>Your practice</span> prescribed relatively{' '}
                  <span style={{ color: color ? "red" : (color == null ? "black" : 'red') }} >{color ? "less red" : (color == null ? "same" : 'more red')}</span> antibiotics{' '}
                  <span style={{ fontWeight: 'bold' }}>last month</span> {' '}
                  <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>compared
                  with the same month last year</span>
                </Typography>
                <br />
                <br />
                <Button to="/dashboard/antibiotics_2" size="large" variant="contained" component={RouterLink}>
                  Click Here
                </Button>
                <br />
                <br />
                <motion.div variants={varBounceIn}>
                  <Typography variant="subtitle2" paragraph>
                    * Red antibiotic should be avoided in first line in general practice ({' '}
                    <Link
                      href="https://antibioticprescribing.ie/"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="always"
                      color="primary"
                    >
                      antibioticprescribing.ie
                    </Link>
                    {' '})
                  </Typography>
                </motion.div>
              </Card>
            </MotionContainer>
          </Container>
      }
    </RootStyle>
  );
}

const mapState = state => ({
  board: state.user.dashboard,
  ab: state.antibiotics.ab,
  audit2: state.audit.audit2,
  abcomparison: state.antibioticscomparisons.abcomparison,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({
  antibiotics: {
    promice_3_4,
  },
  antibioticscomparisons: {
    compare_4,
  },
  audit: { audit_1, audit_2, audit_3, auditMonth }
}) => ({
  compare_4: (payload) => compare_4(payload),
  promice_3_4: (payload) => promice_3_4(payload),
  audit_2: (payload) => audit_2(payload),
  auditMonth: (payload) => auditMonth(payload),
});

export default connect(mapState, mapDispatch)(DashButton);
