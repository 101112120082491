
import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import {getData,postData} from './auxilarFunctions';
import config from '../config.json';



// count model
export const practice = {
    state: {
        id: null,
        contactperson: null,
        gpsnumber: null,
        nursesnumber: null,
        manager: null,
        eircode: null,
        mainhospitalid: null,
        secondhospitalid: null,
        placeid: null,
        laboratoryid: null,
        dispensingpractice: null,
        termsAccepted: false,
        hospitals: null,
        places: null,
        laboritires: null
    },

    reducers: {
        updateDetails(payload,state) {
            return {
                ...state,
                id: payload.id,
                contactperson: payload.contactperson,
                gpsnumber: payload.gpsnumber,
                nursesnumber: payload.nursesnumber,
                manager: payload.manager,
                eircode: payload.eircode,
                mainhospitalid: payload.mainhospitalid,
                secondhospitalid: payload.secondhospitalid,
                laboratoryid: payload.laboratoryid,
                placeid: payload.placeid,
                dispensingpractice: payload.dispensingpractice,
                termsAccepted: payload.termsAccepted
            }
        },
        updateHospitals(state,payload) {
            return {
                ...state,
                hospitals: payload,
            }
        },
        updateLabotiries(state,payload) {
            return {
                ...state,
                laboritires: payload,
            }
        },
        updatePlaces(state,payload) {
            return {
                ...state,
                places: payload,
            }
        },


    },
    effects: (dispatch) => ({
        async update(payload,state) {
          this.updateDetails(payload);
          return;
        },

        async compleatedetails(payload, state) {
            const data = await postData(config.url + '/practice/compleatedetails', {
                userid: state.user.id,
                email: payload.email,
                contactperson: payload.contactperson, gpsnumber: payload.gpsnumber,
                nursesnumber: payload.nursesnumber, manager: payload.manager, eircode: payload.eircode,
                mainhospitalid: payload.mainhospitalid, secondhospitalid: payload.secondhospitalid, placeid: payload.placeid,
                laboratoryid: payload.laboratoryid, dispensingpractice: payload.dispensingpractice,termsAccepted: payload.termsAccepted
            }, state.user.access_token)
                .then(data => {
                   this.updateDetails(data.practice);
                   state.user.profileUpdated;
                   return (data);
                });
            
            return (data.success);
        },

        async setPractice(payload, state) {
            const data = await postData(config.url + '/practice/setPractice', {
                userid: state.user.id,
                email: payload.email,
                contactperson: payload.contactperson, gpsnumber: payload.gpsnumber,
                nursesnumber: payload.nursesnumber, manager: payload.manager, eircode: payload.eircode,
                mainhospitalid: payload.mainhospitalid, secondhospitalid: payload.secondhospitalid, placeid: payload.placeid,
                laboratoryid: payload.laboratoryid, dispensingpractice: payload.dispensingpractice,termsAccepted: payload.termsAccepted
            }, state.user.access_token)
                .then(data => {
                   this.updateDetails(data.practice);
                   state.user.profileUpdated;
                   return (data);
                });
            
            return (data.success);
        },

        async getHospitals(payload,state) {
            return getData(config.url + '/practice/hospitals', state.user.access_token)
                .then(data => {
                   this.updateHospitals(data)

                });
        },

        async getLaboratories(payload,state) {
            return getData(config.url + '/practice/laboratories', state.user.access_token)
                .then(data => {
                   this.updateLabotiries(data)

                });
        },

        async getPlaces(payload,state) {
            return getData(config.url + '/practice/places', state.user.access_token)
                .then(data => {
                   this.updatePlaces(data)

                });
        },
    })
};