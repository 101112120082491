import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';
import { Navigate, useRoutes, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

const register = function Register(props) {
  let { email, token, jwttoken } = useParams();
  const [state, setState] = useState(0); // 0 -> verifying, 1->verified, 2-> error 

  let verifyBackend = (token, email, jwt) => {

    return fetch('https://cara.khired.pk/api/user/verify', {
      method: 'post',
      headers: new Headers({
        'Authorization': 'Bearer ' + jwt,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ token, email })
    });
  }

  useEffect(() => {
    // Update the document title using the browser API
    props.verify({ token, email, jwttoken }) //jwt travels with the header
      .then(() => setState(1))
      .catch((err) => { setState(2); })
  });

  let verified = <div>
    <Box sx={{ mb: 5 }}>
      <Typography variant="h4" gutterBottom>
        Account Successfully Verified
      </Typography>
    </Box>


    <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
      Login
    </Link>


  </div>

  return (
    <RootStyle title="Register | Minimal-UI">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout>


      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {state == 0 ?
                "Verifying account"
                :
                state == 1 ?
                  verified

                  :
                  "Error while verifying"
              }

            </Typography>

          </Box>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}


const mapState = state => ({

});

const mapDispatch = ({
  user: { verify } }) => ({
    verify: (payload) => verify(payload)
  });

export default connect(null, mapDispatch)(register);
