 // material
import { Box, Grid, Container, Typography, Paper, Stack, Button } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppABPiesComparison,

} from '../components/_dashboard/comparison';
import ComparisonsFilters from '../components/_dashboard/comparisonsfilters';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// utils
import { fNumber } from '../utils/formatNumber';
//
import { BaseOptionChart } from '../components/charts';
import { alpha, styled } from '@mui/material/styles';
import { shadows } from '@mui/system';


// ----------------------------------------------------------------------

function DashboardApp(props) {
  const chartOptionsComparisons = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: { 
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => 'Number of antibiotics prescribed:'
        }
      },
    },
    colors: [
      '#26734D',
      '#cc3311',

    ],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '20px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
       pie: {
      customScale: 0.7,
      donut: { labels: { show: false } },
      dataLabels: {
      offset: -20,
    },
    }
    },
  });   
  const red = props.ab.practice_red_lastmonth*100/props.generalAB.total_lastmonth;
  const otherred = props.ab.otherpractice_red_lastmonth*100/props.generalAB.othertotal_lastmonth;

  const difference = red > otherred;
  const difference2 = red < otherred;
  return (
    <Page sx={{backgroundColor: '#fff'}}>
      <Container maxWidth="xl" >
      <Box sx={{ pt: 1 }}>
         
        </Box>

        <Grid container spacing={6}>       
          <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="h2" color='secondary'>Antibiotics</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={12} id="antibiotics">
          <Stack 
          direction="row" 
          justifyContent="space-evenly" 
          alignItems="center"
          sx={{ minWidth: 0 }} 
          spacing={6}>
          <Box sx={{ minWidth: 400 }}>            
             <ReactApexChart type="pie" 
            series={[props.ab.practice_green_lastmonth, props.ab.practice_red_lastmonth]} 
            options={chartOptionsComparisons} 
            />
          <Typography variant="subtitle2" align='center' >Your practice</Typography>     
          </Box>
          <Box sx={{ minWidth: 400 }}>            
             <ReactApexChart type="pie" 
            series={[props.ab.otherpractice_green_lastmonth, props.ab.otherpractice_red_lastmonth]} 
            options={chartOptionsComparisons} 
            />
          <Typography variant="subtitle2" align='center' >All practices average</Typography>     
          </Box>   
          <Box>
          <Typography variant="h3" color='secondary'>Last month, your practice prescribed relatively <i>{difference ? 'more' :(difference2 ? 'less' : 'the same percentage of')}</i> red antibiotics compared to other practices.</Typography>
          </Box>
          </Stack>
          <Typography variant="subtitle3" color='secondary'>*Red antibiotics should be avoided in first line in primary care (see <a href='https://www.hse.ie/eng/services/list/2/gp/antibiotic-prescribing/'>antibioticprescribing.ie</a>)</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
          <br />
          <br />
          <Box 
          sx={{ maxWidth: 800 }}
          align= 'center'>
          <Button variant="contained" href='/dashboard/antibiotics' style={{ fontsize: '12px', padding: '20px', width: 400 }} >Find out more</Button>
          </Box>       
          </Grid>


        </Grid>
      </Container>
    </Page>
  );
}
import { connect } from "react-redux";
const mapState = state => ({
  ab: state.antibiotics.ab,
  generalAB: state.antibiotics.generalAB
 });
 
 const mapDispatch = ({ 
  antibiotics: {getGeneralAB}}) => ({
    getGeneralAB: () => getGeneralAB()
 });
 
 export default connect(mapState, mapDispatch)(DashboardApp);

