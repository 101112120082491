import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, Grid, CardHeader, Typography, Button } from '@mui/material';
// utils
import { fNumber, findNextMultipleOf500 } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import React, { useState, useEffect } from "react";


// ----------------------------------------------------------------------




function AppConversionRates(props) {

  const [refreshGraph, setRefreshGraph] = useState(false);
  const [ageGroupYaxis, setAgeGroupYaxis] = useState(null);


  useEffect(() => {
    if (props.age && props.age !== undefined) {
      // If data has changed, trigger graph refresh
      setRefreshGraph(prev => !prev);
    }
  }, [props.age]);

  useEffect(() => {
    const nextMultipleOf500 = findNextMultipleOf500(
      props.age.green,
      props.age.red
    );
    setAgeGroupYaxis(nextMultipleOf500);
  }, [props.age.green, props.age.red]);

  const CHART_DATA = [{
    name: ['Green antibiotics'],
    data: props.age.green,
    color: '#26734D'
  }, {
    name: ['Red antibiotics'],
    data: props.age.red,
    color: '#cc3311'
  },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    annotations: {
      yaxis: [
        {
          x: 0,
          borderColor: "#000",
          label: {
            borderColor: "#000",
            style: {
              color: "#fff",
              background: "#000",
            },
          },
        },
      ],
    },
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Percentage of all antibiotics: `
        },
        formatter: function (val, { seriesIndex, w }) {
          const totalGreen = w.config.series[0].data.reduce((acc, curr) => acc + curr, 0);
          const totalRed = w.config.series[1].data.reduce((acc, curr) => acc + curr, 0);
          const total = totalGreen + totalRed;
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        }        
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar:
      {
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
        columnWidth: '60%',
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: true,
    formatter: function (val, opt) {
      return fNumber(val);
    },
      style: {
        colors: ['#708090'],
      },
      offsetY: -25
    },
    xaxis: {
      categories: props.age.categories
    },
    yaxis: {
      show: false,
      max: ageGroupYaxis,
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
      position: "bottom",
      customLegendItems: ["Green antibiotics", "Red antibiotics"],
    },
  });

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <Typography variant="h6" mt={4} color='#22577A'>Antibiotic prescriptions by age group.</Typography>
      <Grid>
        <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            height={480}
            key={refreshGraph}
          />
        </Box>
      </Grid>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  age: state.antibiotics.age
});

const mapDispatch = ({
  antibiotics: { getGeneralAB } }) => ({
    getGeneralAB: () => getGeneralAB()
  });

export default connect(mapState, mapDispatch)(AppConversionRates);
