import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
// material
import { Box, Grid, Container, Typography, Paper, Stack, Button, Divider, MenuItem, Link } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// components
import Page from '../components/Page';
// ----------------------------------------------------------------------


export default function DashboardSelectionApp() {
   const [value, setValue] = React.useState([]);

  return (
    <Page sx={{backgroundColor: '#fff'}}>
      <Container maxWidth="xl" >
      <Box sx={{ pb: 5 }}>
         
        </Box>
        <Grid container spacing={12}>       
          <Grid item xs={3} sm={6} md={12}>
          <Typography variant="h2" color='secondary'>Select a dashboard</Typography>
          <br />
          <br></br>
          <br />
          <Typography variant="h4" color='secondary'>Most viewed dashboards</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={12} >
          <Stack 
          direction="row" 
          divider={<Divider orientation="vertical" sx={{color:"#22577a"}} flexItem />}
          justifyContent="flex-start" 
          sx={{ minWidth: 0 }} 
          spacing={6}>
          <item>
          <Button href="./antibiotics"><Typography variant="h6" color='secondary'>Antibiotics</Typography></Button>
          </item>
          <item>
          <Button href="./practiceOverview"><Typography variant="h6" color='secondary'>Practice Overview</Typography></Button>
          </item>
          </Stack>          
          </Grid>
          <Grid item xs={12} sm={6} md={12} >
          <Typography variant="h4" color='secondary'>Or select from list:</Typography>
          <Box>
            <FormControl sx={{ minWidth: 500 }}>
              <Select 

                  labelId='dashboard selection'
                  id='select_dashboard_list'
                  value={value}
              >
                <Link href="/dashboard/nathalyone" underline="none" sx={{color: '#22577A'}}><MenuItem value={'Nathaly1'}>Nathaly1</MenuItem></Link>
                <Link href="/dashboard/nathalytwo" underline="none" sx={{color: '#22577A'}}><MenuItem value={'Nathaly2'} >Nathaly2</MenuItem></Link>
                <Link href="/dashboard/app" underline="none" sx={{color: '#22577A'}}><MenuItem value={"antibiotics"}>Antibiotics</MenuItem></Link>
                <Link href="/dashboard/practiceoverview" underline="none" sx={{color: '#22577A'}}><MenuItem value={"practiceoverview"}>Practice Overview</MenuItem></Link>
                <MenuItem value={"CDM"} disabled>Chronic Disease Management</MenuItem>
                <MenuItem value={'Vaccinations'} disabled>Vaccinations</MenuItem>
                <MenuItem value={'Cancer'} disabled>Cancer</MenuItem>
              </Select>
            </FormControl>
          </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
