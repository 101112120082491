import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  Stack,
  Box,
  Typography,
  alpha,
  Grid,
} from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppCurrentVisits(props) {
  const [maxYConsultations, setMaxYConsultations] = useState(0);
  const [maxYConsultationsAB, setMaxYConsultationsAB] = useState(0);
  const [maxYPrescriptions, setMaxYPrescriptions] = useState(0);
  const [maxYPrescriptionsAB, setMaxYPrescriptionsAB] = useState(0);
  useEffect(() => {
    if (props.consultations.all && props.consultationscomparison.otherall) {
      const maxVal =
        Math.max(
          props.consultations.all,
          props.consultationscomparison.otherall
        ) * 1.1;
      const maxCons = Math.ceil(maxVal / 1000) * 1000;

      setMaxYConsultations(maxCons);
    }
  }, [props.consultations.all, props.consultationscomparison.otherall]);

  useEffect(() => {
    if (props.consultations.ab && props.consultationscomparison.otherab) {
      const maxVal =
        Math.max(
          props.consultations.ab,
          props.consultationscomparison.otherab
        ) * 1.1;
      const maxCons = Math.ceil(maxVal / 1000) * 1000;
      setMaxYConsultationsAB(maxCons);
    }
  }, [props.consultations.ab, props.consultationscomparison.otherab]);

  useEffect(() => {
    if (props.prescriptions.all && props.prescriptionscomparison.otherall) {
      const maxVal =
        Math.max(
          props.prescriptions.all,
          props.prescriptionscomparison.otherall
        ) * 1.1;
      const maxCons = Math.ceil(maxVal / 1000) * 1000;
      setMaxYPrescriptions(maxCons);
    }
  }, [props.prescriptions.all, props.prescriptionscomparison.otherall]);

  useEffect(() => {
    if (props.prescriptions.ab && props.prescriptionscomparison.otherab) {
      const maxVal =
        Math.max(
          props.prescriptions.ab,
          props.prescriptionscomparison.otherab
        ) * 1.1;
      const maxCons = Math.ceil(maxVal / 1000) * 1000;
      setMaxYPrescriptionsAB(maxCons);
    }
  }, [props.prescriptions.ab, props.prescriptionscomparison.otherab]);

  const CHART_DATA_Consultations = [
    {
      data: [
        {
          x: "Your practice",
          y: props.consultations.all,
          color: "#3E8397",
        },
        {
          x: "Filtered practices",
          y: props.consultationscomparison.otherall,
          color: alpha("#3E8397", 0.5),
        },
      ],
    },
  ];

  const CHART_DATA_ConsultationsAB = [
    {
      data: [
        {
          x: "Your practice",
          y: props.consultations.ab,
          seriesName: "Your practice",
          color: "#3E8397",
        },
        {
          x: "Filtered practices",
          y: props.consultationscomparison.otherab,
          seriesName: "Filtered practices",
          color: alpha("#3E8397", 0.5),
        },
      ],
    },
  ];

  const CHART_DATA_Prescriptions = [
    {
      data: [
        {
          x: "Your practice",
          y: props.prescriptions.all,
          color: "#3E8397",
        },
        {
          x: "Filtered practices",
          y: props.prescriptionscomparison.otherall,
          color: alpha("#3E8397", 0.5),
        },
      ],
    },
  ];

  const CHART_DATA_PrescriptionsAB = [
    {
      data: [
        {
          x: "Your practice",
          y: props.prescriptions.ab,
          color: "#3E8397",
          seriesName: "Your practice",
        },
        {
          x: "Filtered practices",
          y: props.prescriptionscomparison.otherab,
          color: alpha("#3E8397", 0.5),
          seriesName: "Filtered practices",
        },
      ],
    },
  ];

  const chartOptions_Consultations = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          if (opts.seriesIndex == 0) {
            const sum = opts.series[0].reduce((a, b) => a + b, 0);
            const percent = (value / sum) * 100;
            return percent.toFixed(0) + "%";
          }
          if (opts.seriesIndex == 1) {
            const sum = opts.series[1].reduce((a, b) => a + b, 0);
            const percent = (value / sum) * 100;
            return percent.toFixed(0) + "%";
          }
        },
        title: {
          formatter: (seriesName) => `Percentage of consultations: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397", alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: true,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
    },
    //labels should show total number
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
        fontWeight: "bold",
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    xaxis: {
      show: false,
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxYConsultations,
    },
    xaxis: {
      type: "category",
      categories: ["All consultations"],
      labels: {
        offsetX: 20,
      },
    },
    stroke: {
      show: false,
    },
  });
  const chartOptions_ConsultationsAB = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, { dataPointIndex }) {
          const seriesName =
            CHART_DATA_PrescriptionsAB[0].data[dataPointIndex].seriesName;
          if (seriesName === "Your practice") {
            const percent = (value / props.consultations.all) * 100;
            return `${seriesName}: ${percent.toFixed(0)}%`;
          } else if (seriesName === "Filtered practices") {
            const percent1 =
              (value / props.consultationscomparison.otherall) * 100;
            return `${seriesName}: ${percent.toFixed(0)}%`;
          }
        },
        title: {
          formatter: (seriesName) => `Percentage of all consultations: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397", alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: true,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
    },
    //labels should show total number
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
        fontWeight: "bold",
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    xaxis: {
      show: false,
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxYConsultationsAB,
    },
    xaxis: {
      type: "category",
      categories: ["All consultations resulting in an antibiotic"],
      labels: {
        offsetX: 23,
      },
    },
    stroke: {
      show: false,
    },
  });
  const chartOptions_Prescribe = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          if (opts.seriesIndex == 0) {
            const sum = opts.series[0].reduce((a, b) => a + b, 0);
            const percent = (value / sum) * 100;
            return percent.toFixed(0) + "%";
          }
          if (opts.seriesIndex == 1) {
            const sum = opts.series[1].reduce((a, b) => a + b, 0);
            const percent = (value / sum) * 100;
            return percent.toFixed(0) + "%";
          }
        },
        title: {
          formatter: (seriesName) => `Percentage of all prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397", alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: true,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    //labels should show total number
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
        fontWeight: "bold",
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxYPrescriptions,
    },
    xaxis: {
      type: "category",
      categories: ["All prescriptions"],
      labels: {
        offsetX: 20,
      },
    },
    stroke: {
      show: false,
    },
  });

  const chartOptions_PrescribeAB = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: false,
      },
      y: {
        formatter: function (value, { dataPointIndex }) {
          const seriesName =
            CHART_DATA_PrescriptionsAB[0].data[dataPointIndex].seriesName;
          if (seriesName === "Your practice") {
            const percent = (value / props.prescriptions.all) * 100;
            return `${seriesName}: ${percent.toFixed(0)}%`;
          } else if (seriesName === "Filtered practices") {
            const percent1 =
              (value / props.prescriptionscomparison.otherall) * 100;
            return `${seriesName}: ${percent1.toFixed(0)}%`;
          }
        },
        title: {
          formatter: (seriesName) => `Percentage of all prescriptions: `,
        },
      },
    },

    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397", alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
        distributed: true,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    //labels should show total number
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
        fontWeight: "bold",
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: maxYPrescriptionsAB,
    },
    xaxis: {
      type: "category",
      categories: ["All antibiotic prescriptions"],
      labels: {
        offsetX: 20,
      },
    },
    stroke: {
      show: false,
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Typography variant="h4" mt={10} color="#22577A">
        Antibiotic consultations and prescriptions in the previous 12 months.
      </Typography>
      <br />
      <br />
      <Typography
        variant="subtitle3"
        color="#3E8397"
        sx={{ fontWeight: "bold" }}
      >
        Your practice
      </Typography>
      <br />
      <Typography
        variant="subtitle3"
        color={alpha("#3E8397", 0.5)}
        sx={{ fontWeight: "bold" }}
      >
        {" "}
        Filtered practices{" "}
      </Typography>
      <Grid container spacing={{ xs: 0, sm: 0, md: 12, lg: 0, xl: 0 }}>
        <Grid item sm={12} md={6} lg={3}>
          <Box dir="ltr">
            <ReactApexChart
              type="bar"
              series={CHART_DATA_Consultations}
              options={chartOptions_Consultations}
              height={480}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Box dir="ltr">
            <ReactApexChart
              type="bar"
              series={CHART_DATA_ConsultationsAB}
              options={chartOptions_ConsultationsAB}
              height={480}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Box dir="ltr">
            <ReactApexChart
              type="bar"
              series={CHART_DATA_Prescriptions}
              options={chartOptions_Prescribe}
              height={480}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <Box dir="ltr">
            <ReactApexChart
              type="bar"
              series={CHART_DATA_PrescriptionsAB}
              options={chartOptions_PrescribeAB}
              height={480}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
//
import { connect } from "react-redux";
import { useEffect, useState } from "react";

const mapState = (state) => ({
  consultations: state.antibiotics.consultations,
  prescriptions: state.antibiotics.prescriptions,
  consultationscomparison: state.antibioticscomparisons.consultationscomparison,
  prescriptionscomparison: state.antibioticscomparisons.prescriptionscomparison,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppCurrentVisits);
