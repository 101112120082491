import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, Stack, CardHeader, Typography, Button, alpha } from '@mui/material';
// utils
import { findNextMultipleOf500, fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import React, { useState, useEffect } from "react";

// ----------------------------------------------------------------------

function AppConversionRates(props) {
  const [greenGroupYaxis, setGreenGroupYaxis] = useState(null);
  const [redGroupYaxis, setRedGroupYaxis] = useState(null);

  useState(() => {
    const nextMultipleOf500 = findNextMultipleOf500(
      props.age.green,
      props.otherage.othergreen
    );

    setGreenGroupYaxis(nextMultipleOf500);
  }, [props.age.green, props.otherage.othergreen]);

  useEffect(() => {
    const nextMultipleOf500 = findNextMultipleOf500(
      props.age.red,
      props.otherage.otherred
    );
    setRedGroupYaxis(nextMultipleOf500);
  }, [props.age.red, props.otherage.otherred]);

  const CHART_DATA_GREEN = [
    {
      name: ["Your practice"],
      data: props.age.green,
      color: "#26734D",
    },
    {
      name: ["Filtered practices"],
      data: props.otherage.othergreen,
      color: alpha("#26734D", 0.5),
    },
  ];

  const CHART_DATA_RED = [
    {
      name: ["Your practice"],
      data: props.age.red,
      color: "#cc3311",
    },
    {
      name: ["Filtered practices"],
      data: props.otherage.otherred,
      color: alpha("#cc3311", 0.5),
    },
  ];

  const chartOptions_GREEN = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return percent.toFixed(0) + "%";
        },
        title: {
          formatter: (seriesName) =>
            `Percentage of all antibiotic prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: props.age.categories,
    },
    yaxis: {
      show: false,
      max: greenGroupYaxis,
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
  });

  const chartOptions_RED = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return percent.toFixed(0) + "%";
        },
        title: {
          formatter: (seriesName) =>
            `Percentage of all antibiotic prescriptions: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: props.age.categories,
    },
    yaxis: {
      show: false,
      max: redGroupYaxis,
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Typography variant="h6" mt={4} color="#22577A">
        Antibiotic prescriptions by age group.
      </Typography>
      <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA_GREEN}
          options={chartOptions_GREEN}
          height={480}
        />
      </Box>
      <br />
      <br />
      <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA_RED}
          options={chartOptions_RED}
          height={480}
        />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  age: state.antibiotics.age,
  otherage: state.antibioticscomparisons.age,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
