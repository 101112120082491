import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, Switch, TextField, IconButton, FormGroup, FormHelperText, InputAdornment, Checkbox, FormControl, FormControlLabel, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Select from '@mui/material/Select';
import { connect } from "react-redux";
import { id } from 'date-fns/locale';



// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const practicesetup = function PracticeSetUp(props) {
    const navigate = useNavigate();
    const [hospitals, setHospitals] = useState([
        {id: 1,
        name: 'Hospital 1'},
        {id: 2,
        name: 'Hospital 2'},
        {id: 3,
        name: 'Hospital 3'},
        {id: 4,
        name: 'Hospital 4'}
    ]);
    const [labs, setLabs] = useState([
        {id: 1,
        name: 'Lab 1'},
        {id: 2,
        name: 'Lab 2'},
        {id: 3,
        name: 'Lab 3'},
        {id: 4,
        name: 'Lab 4'}
    ]);
    const [place, setPlace] = useState([
        {id: 1,
        name: 'Big (inner city)'},
        {id: 2,
        name: 'Suburbs'},
        {id: 3,
        name: 'Small (town)'},
        {id: 4,
        name: 'Mixed urban-rural'},
        {id: 5,
            name: 'Rural'}
    ]);
  


    const RegisterSchema = Yup.object().shape({

        contactperson: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Contact person required'),
        gpsnumber: Yup.number()
            .min(1, 'At least one!')
            .required('Contact person required'),
        nursesnumber: Yup.number()
            .min(1, 'At least one!')
            .required('Contact person required'),
        manager: Yup.boolean()
        .required('Contact person required'),
        eircode: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Eircode required'),
        mainhospitalid: Yup.number()
            .required('Contact person required'),
        secondhospitalid: Yup.number()
            .required('Contact person required'),
        placeid: Yup.number()
            .required('Contact person required'),
        laboratoryid: Yup.number()
            .required('Contact person required'),
        dispensingpractice: Yup.boolean()
            .required('Contact person required'),
        termsAccepted: Yup.boolean()
            .oneOf([true], 'Field must be checked')

    });

    const formik = useFormik({
        initialValues: {
            contactperson: props.practice.contactperson,
            gpsnumber: props.practice.gpsnumber,
            nursesnumber: props.practice.nursesnumber,
            manager: props.practice.manager,
            eircode: props.practice.eircode,
            mainhospitalid: props.practice.mainhospitalid,
            secondhospitalid: props.practice.secondhospitalid,
            placeid:  props.practice.placeid,
            laboratoryid:  props.practice.laboratoryid,
            dispensingpractice:  props.practice.dispensingpractice,
            termsAccepted: true
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            // navigate('/dashboard', { replace: true });
            console.log("on submit", values)
            props.compleatedetails({...values, id: props.practice.id})
            // handleOpen(0)
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Edit User details</Typography>
                </Box>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}  >
                    <Grid item xs={12} sm={6} md={6}>
                        <FormikProvider value={formik}>

                            <Form autoComplete="off" onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                            fullWidth
                                            label="Practice name"
                                            disabled={true}
                                            value={props.user.username}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Contact person"
                                            {...getFieldProps('contactperson')}
                                            error={Boolean(touched.contactperson && errors.contactperson)}
                                            helperText={touched.contactperson && errors.contactperson}
                                        />
                                        
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                   
                                        <TextField
                                            fullWidth
                                            label="Number of GPs"
                                            {...getFieldProps('gpsnumber')}
                                            error={Boolean(touched.gpsnumber && errors.gpsnumber)}
                                            helperText={touched.gpsnumber && errors.gpsnumber}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Number of nurses"
                                            {...getFieldProps('nursesnumber')}
                                            error={Boolean(touched.nursesnumber && errors.nursesnumber)}
                                            helperText={touched.nursesnumber && errors.nursesnumber}
                                        />
                                    </Stack>


                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl
                                     error={Boolean(touched.manager && errors.manager)}>
                                        <FormControlLabel
                                           
                                            control={
                                                <Switch
                                                checked={getFieldProps('manager').value}

                                                    {...getFieldProps('manager')}
                                                />
                                            }
                                            label="Practice Manager"
                                        />
                                         <FormHelperText>{errors.manager}</FormHelperText>
                                    </FormControl>
                                        
                                        <TextField
                                            fullWidth
                                            label="Eircode"
                                            {...getFieldProps('eircode')}
                                            error={Boolean(touched.eircode && errors.eircode)}
                                            helperText={touched.eircode && errors.eircode}
                                        />

                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                                        <FormControl
                                            error={Boolean(touched.mainhospitalid && errors.mainhospitalid)}
                                            fullWidth>
                                            <InputLabel id="demo-simple-select-label">Main Hospital</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                {...getFieldProps('mainhospitalid')}
                                                label="Main Hospital"

                                            >

                                                {props.options.hospitals.map((hosp)=> <MenuItem value={hosp.id}>{hosp.name}</MenuItem>)}
                                            </Select>
                                            <FormHelperText id="my-helper-text">{errors.mainhospitalid}</FormHelperText>

                                        </FormControl>

                                        <FormControl
                                            error={Boolean(touched.secondhospitalid && errors.secondhospitalid)}
                                            fullWidth>
                                            <InputLabel id="demo-simple-select-label">Second Hospital</InputLabel>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Second Hospital"
                                                {...getFieldProps('secondhospitalid')}
                                            >
                                                 {props.options.hospitals.map((hosp)=> <MenuItem value={hosp.id}>{hosp.name}</MenuItem>)}
                                                
                                            </Select>
                                            <FormHelperText id="my-helper-text">{errors.secondhospitalid}</FormHelperText>
                                        </FormControl>





                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                    <FormControl
                                            error={Boolean(touched.laboratoryid && errors.laboratoryid)}
                                            fullWidth>
                                            <InputLabel id="demo-simple-select-label">Laboratory</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Second Hospital"
                                                {...getFieldProps('laboratoryid')}

                                            >
                                                 {props.options.laboratories.map((lab)=> <MenuItem value={lab.id}>{lab.name}</MenuItem>)}
                                               
                                            </Select>
                                            <FormHelperText id="my-helper-text">{errors.laboratoryid}</FormHelperText>

                                        </FormControl>

                                    <FormControl
                                     error={Boolean(touched.dispensingpractice && errors.dispensingpractice)}>
                                        <FormControlLabel
                                           
                                            control={
                                                <Switch
                                                checked={getFieldProps('dispensingpractice').value}

                                                    {...getFieldProps('dispensingpractice')}
                                                />
                                            }
                                            label="Dispensing practice"
                                        />
                                         <FormHelperText>{errors.dispensingpractice}</FormHelperText>
                                    </FormControl>
                                  
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                           
                                    <FormControl
                                            error={Boolean(touched.placeid && errors.placeid)}
                                            fullWidth>
                                            <InputLabel id="demo-simple-select-label">Practice Place</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Second Hospital"
                                                {...getFieldProps('placeid')}

                                            >
                                                 {props.options.places.map((lab)=> <MenuItem value={lab.id}>{lab.name}</MenuItem>)}
                                               
                                            </Select>
                                            <FormHelperText id="my-helper-text">{errors.laboratoryid}</FormHelperText>

                                        </FormControl>
                                       
                                        </Stack>

                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <FormControl
                                        error={Boolean(touched.termsAccepted && errors.termsAccepted)}>
    
                                            <FormControlLabel
                                                componentsProps={{error: true}}
                                                control={
                                                    <Checkbox
                                                        {...getFieldProps('termsAccepted')}
                                                        checked={getFieldProps('termsAccepted').value}
                                                        disabled={true}

                                                    />}
                                                label="I agree to participate in the CARA Network and have read the research agreement"
                                                labelPlacement="end"
                                            />
                                              <FormHelperText>{touched.termsAccepted && errors.termsAccepted}</FormHelperText>
                                    </FormControl>
                                        </Stack>
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}


const mapState = state => ({
user: state.user, 
practice: state.practice,
options: state.options

});

const mapDispatch = ({
  practice: { compleatedetails } }) => ({
    compleatedetails: (payload) => compleatedetails(payload)
  });

export default connect(mapState, mapDispatch)(practicesetup);

