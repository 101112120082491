import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";


const sb = function SimpleSnackbar(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
console.log(props)
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={()=>{props.refreshToken(), setOpen(false)}}>
        Stay Logged In
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>       

      <Snackbar
        open={props.session.showAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Your session is about to end " + Math.ceil(props.session.tte) + "s"}
        action={action}
        anchorOrigin={{vertical: "bottom", horizontal:  "right" }}
      />
    </div>
  );
}



const mapState = state => ({
    session: state.session
  });
  
  const mapDispatch = ({
    user: { refreshToken } }) => ({
      refreshToken: () => refreshToken()
    });
  
  export default connect(mapState, mapDispatch)(sb);