import { useNavigate } from "react-router-dom";
import {
  isJwtExpired,
  timeToExpire,
} from "../components/authentication/checkJWT";
import { getData, postData } from "./auxilarFunctions";
import config from "../config.json";

// count model
export const audit = {
  state: {
    audit1: {
      start1: new Date().toDateString(),
      end1: new Date().toDateString(),
      start2: new Date().toDateString(),
      end2: new Date().toDateString(),
      all_consultations_1: null,
      ab_consultations_1: null,
      all_prescriptions_1: null,
      ab_prescriptions_1: null,
      all_consultations_2: null,
      ab_consultations_2: null,
      all_prescriptions_2: null,
      ab_prescriptions_2: null,
    },
    audit2: {
      year1_green: null,
      year2_green: null,
      year1_red: null,
      year2_red: null,
    },
    audit3: null,
    auditDetails: {
      tf: 0,
      goal: "Goal2",
    }
  , 

  },

  reducers: {
    updateAudit1(state, payload) {
      return {
        ...state,
        audit1: {
          ...state.audit1,
          all_consultations_1: payload?.all_consultations_1,
          ab_consultations_1: payload?.ab_consultations_1,
          all_prescriptions_1: payload?.all_prescriptions_1,
          ab_prescriptions_1: payload?.ab_prescriptions_1,
          all_consultations_2: payload?.all_consultations_2,
          ab_consultations_2: payload?.ab_consultations_2,
          all_prescriptions_2: payload?.all_prescriptions_2,
          ab_prescriptions_2: payload?.ab_prescriptions_2,
        },
      };
    },
    updateAudit2(state, payload) {
      const typesPrescribed = [];
      let practiceGreenTotal = 0;
      let practiceRedTotal = 0;
      let otherPracticeGreenTotal = 0;
      let otherPracticeRedTotal = 0;
      for (const item of payload[0]) {
        if (item.ab_classification === "Green") {
            practiceGreenTotal += parseInt(item.total);
        }
        if (item.ab_classification === "Red") {
          practiceRedTotal += parseInt(item.total);
        }
    }

    for (const item of payload[1]) {
        if (item.ab_classification === "Green") {
            otherPracticeGreenTotal += parseInt(item.total);
        }
        if (item.ab_classification === "Red") {
          otherPracticeRedTotal += parseInt(item.total);
        }
    }
      return {
        ...state,
        audit2: {
          ...state.audit2,
          year1_green: practiceGreenTotal,
          year2_green: otherPracticeGreenTotal,
          year1_red: practiceRedTotal,
          year2_red: otherPracticeRedTotal,
        },
      };
    },
    updateAudit3(state, payload) {
      return {
        ...state,
        audit3: payload ? payload : null,
      };
    },
    updateDates(state, payload) {
      return {
        ...state,
        audit1: {
          ...state.audit1,
          start1: payload.start1,
          end1: payload.end1,
          start2: payload.start2,
          end2: payload.end2,
        },
      };
    },
    updateTf(state, payload) { 
      return {
        ...state,
        auditDetails: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async audit_1(payload, state) {
      const promise_5 = await postData(
        config.url + "/audit/audit1",
        {
          start1: payload.payload.start1,
          end1: payload.payload.end1,
          start2: payload.payload.start2,
          end2: payload.payload.end2,
        },
        state.user.access_token
      );
      this.updateAudit1(promise_5);
      return;
    }, 
    async audit_2(payload, state) {
      const promise_5 = await postData(
        config.url + "/audit/audit2",
        {
          start1: payload.payload.start1,
          end1: payload.payload.end1,
          start2: payload.payload.start2,
          end2: payload.payload.end2,
        },
        state.user.access_token
      );
      this.updateAudit2(promise_5);
      return;
    },
    async audit_3(payload, state) {
      const promise_5 = await postData(
        config.url + "/audit/audit3",
        {
          start1: payload.payload.start1,
          end1: payload.payload.end1,
          start2: payload.payload.start2,
          end2: payload.payload.end2,
        },
        state.user.access_token
      );
      this.updateAudit3(promise_5);
      return;
    },
    async update(payload, state) {
      this.updateDetails(payload);
      return;
    },
    async auditMonth(payload, state) {
      this.updateTf(payload);
      return;
    },
  }),
};
