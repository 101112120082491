import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const generateData = (baseval, count, yrange)=> {
  let i = 0;
  const series = [];
  while (i < count) {     
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      const z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

      series.push([baseval, y, z]);
      baseval += 86400000;
      i++;
  }
  return series;
}


const CHART_DATA = [{
  name: 'Amoxicillin',
  data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
    min: 10,
    max: 60
  })
},
{
  name: 'Doxycycline',
  data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
    min: 10,
    max: 60
  })
},
{
  name: 'Cephalexin',
  data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
    min: 10,
    max: 60
  })
},
{
  name: 'Sulfamethoxazole ',
  data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
    min: 10,
    max: 60
  })
}];

export default function AppWebsiteVisits() {
  const chartOptions = merge(BaseOptionChart(), {
  
    dataLabels: {
      enabled: false
  },
  fill: {
      opacity: 0.8
  },
  title: {
      text: ''
  },
  xaxis: {
      tickAmount: 12,
      type: 'category',
  },
  yaxis: {
      max: 70
  }
  });

  return (
    <Card>
      <CardHeader title="Antibiotics by Age" subheader="(+43%) than last year" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="bubble" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
