// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Paper,
  Stack,
  TextField,
  Button,
} from "@mui/material";
// components
import Page from "../components/Page";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import PopupModal from "src/components/_dashboard/audit/popupModal";
import ReactGA from "react-ga4";

// import { GridPrintExportOptions } from '@mui/x-data-grid';

// ----------------------------------------------------------------------

function DashboardApp(props) {
  const [goal, setGoal] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [days, setDays] = React.useState(false);
  const [dateEnd, setDateEnd] = React.useState(props.lastUpdata);
  const [tf, setTf] = React.useState();
  const navigate = useNavigate();
  const dashNo = props.user.dashboard;

  // useEffect(() => {
  //   const eventCategory = "Auditview "+dashNo;
  //   const eventPage = "Audit Page "+dashNo;


  //   ReactGA.send({
  //     hitType: "event",  // Instead of "pageview", we use "event" for custom tracking
  //     eventCategory: eventCategory,  // Define your own category
  //     eventAction: eventCategory,   // Or "pageview2" depending on your needs
  //     eventLabel: window.location.pathname,  // The current path
  //     title: eventPage,  // Keep the title if necessary
  //   });
  // }, []);

  const ValueChange = (e) => {
    setGoal(e.target.value);
  };
  const ValueChange2 = (e) => {
    setTf(e.target.value);
  };
  // let goals = ["Reduce the percentage of antibiotic prescriptions", "Reduce the percentage of red antibiotic prescriptions", "Reduce the number of antibiotic prescriptions" ]
  // let tfs = ["1 month", "3 months", "12 months"]

  const handleDays = (text) => {
    setDays(text);
    setOpen(true);
  };

  const redirectUser = async () => {
    const auditPayload = {
      tf: tf,
      goal: goal,
    }
    await props.auditMonth({ auditPayload });
    if (goal === "Goal1") {
      const start1 = new Date(dateEnd);
      const end1 = new Date(start1);
      end1.setMonth(end1.getMonth() - (tf === "0" ? 1 : tf === "1" ? 3 : 12));
  
      const start2 = new Date(start1);
      start2.setMonth(start2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
      const end2 = new Date(end1);
      end2.setMonth(end2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
  
      const payload = {
        start1: start1,
        end1: end1,
        start2: start2,
        end2: end2,
      };
  
      console.log("Reached :", { payload });
  
      // Perform the audit and redirect
      await props.audit_1({ payload });
      let path=''
      switch(dashNo){
        case "1":
          path="/dashboard/antibiotics/audit/report1";
          break;
        case "2":
          path="/dashboard/antibiotics/audit/report1_1";
          break;
        case "3":
          path="/dashboard/antibiotics/audit/report1_2";
          break;
      }
      navigate(path);
    }
    
    if (goal === "Goal2") {
      const start1 = new Date(dateEnd);
      const end1 = new Date(start1);
      end1.setMonth(end1.getMonth() - (tf === "0" ? 1 : tf === "1" ? 3 : 12));
  
      const start2 = new Date(start1);
      start2.setMonth(start2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
      const end2 = new Date(end1);
      end2.setMonth(end2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
  
      const payload = {
        start1: start1,
        end1: end1,
        start2: start2,
        end2: end2,
      };
  
      console.log("Reached :", { payload });
  
      // Perform the audit and redirect
      await props.audit_2({ payload });
      let path=''
      switch(dashNo){
        case "1":
          path="/dashboard/antibiotics/audit/report2";
          break;
        case "2":
          path="/dashboard/antibiotics/audit/report2_1";
          break;
        case "3":
          path="/dashboard/antibiotics/audit/report2_2";
          break;
      }
      navigate(path);
    }
  
    if (goal === "Goal3") {
      const start1 = new Date(dateEnd);
      const end1 = new Date(start1);
      end1.setMonth(end1.getMonth() - (tf === "0" ? 1 : tf === "1" ? 3 : 12));
  
      const start2 = new Date(start1);
      start2.setMonth(start2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
      const end2 = new Date(end1);
      end2.setMonth(end2.getMonth() - (tf === "0" ? 12 : tf === "1" ? 12 : 12));
  
      const payload = {
        start1: start1,
        end1: end1,
        start2: start2,
        end2: end2,
      };
  
      console.log("Reached :", { payload });
  
      // Perform the audit and redirect
      await props.audit_3({ payload });

      let path=''
      switch(dashNo){
        case "1":
          switch(tf){
            case "0":
              path="/dashboard/antibiotics/audit/report3";
              break;
            case "1":
              path="/dashboard/antibiotics/audit/report3b";
              break;
            case "2":
              path="/dashboard/antibiotics/audit/report3c";
              break;
          }
          break;
        case "2":
          switch(tf){
            case "0":
              path="/dashboard/antibiotics/audit/report3_1";
              break;
            case "1":
              path="/dashboard/antibiotics/audit/report3b_1";
              break;
            case "2":
              path="/dashboard/antibiotics/audit/report3c_1";
              break;
          }
          break;
        case "3":
          switch(tf){
            case "0":
              path="/dashboard/antibiotics/audit/report3_2";
              break;
            case "1":
              path="/dashboard/antibiotics/audit/report3b_2";
              break;
            case "2":
              path="/dashboard/antibiotics/audit/report3c_2";
              break;
          }
          break;
        }
        console.log("path",path);
        navigate(path);

     
    }
  };
  

  return (
    <Page title="Dashboard/Audit" sx={{ backgroundColor: "#fff" }}>
      {open ? (
        <PopupModal open={open} setOpen={setOpen} text={days}></PopupModal>
      ) : null}
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={12}>
            <Typography variant="h2" color="secondary">
              Audit
            </Typography>
            <br />
            <br />
            <Typography variant="subtitle1" color="secondary">
              To carry out your audit, set your goal and decide a timeframe.
            </Typography>
            <br />
            <br />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h4" color="secondary">
              1. Goal
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl>
              <RadioGroup
                aria-labelledby="radio-buttons-group-goals"
                name="radio-buttons-goals"
              >
                <FormControlLabel
                  value="Goal1"
                  onChange={ValueChange}
                  control={<Radio />}
                  label="Reduce the percentage of antibiotic prescriptions"
                />
                <FormControlLabel
                  value="Goal2"
                  onChange={ValueChange}
                  control={<Radio />}
                  label="Reduce the percentage of red antibiotic prescriptions"
                />
                <FormControlLabel
                  value="Goal3"
                  onChange={ValueChange}
                  control={<Radio />}
                  label="Reduce the number of antibiotic prescriptions"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid item xs={2} sm={2} md={6}>
            <Typography variant="h4" color="secondary">
              2. Timeframe
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl>
              <RadioGroup
                aria-labelledby="radio-buttons-group-tf"
                name="radio-buttons-tf"
              >
                <FormControlLabel
                  value="0"
                  onChange={ValueChange2}
                  control={<Radio />}
                  label="1 month"
                />
                <FormControlLabel
                  value="1"
                  onChange={ValueChange2}
                  control={<Radio />}
                  label="3 months"
                />
                <FormControlLabel
                  value="2"
                  onChange={ValueChange2}
                  control={<Radio />}
                  label="12 months"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>

          <Grid item xs={6} sm={6} md={6}>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={redirectUser}
            >
              <Typography variant="subtitle1" color="secondary">
                Generate report
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapState = (state) => ({
  user: state.user,
  audit1: state.audit.audit1,
  audit2: state.audit.audit2,
  audit3: state.audit.audit3,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ audit: { audit_1, audit_2, audit_3, auditMonth } }) => ({
  audit_1: (payload) => audit_1(payload),
  audit_2: (payload) => audit_2(payload),
  audit_3: (payload) => audit_3(payload),
  auditMonth: (payload) => auditMonth(payload),
});

export default connect(mapState, mapDispatch)(DashboardApp);
