
import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import {postData, getData} from './auxilarFunctions';
import config from '../config.json';



// count model
export const charts = {
    state: {
        model1: [],
        model2: []
    },

    reducers: {
        updateModel1(state, payload) {
            return {
              ...state,
              model1: payload
            }
        },
        updateModel2(state, payload) {
            return {
              ...state,
              model2: payload
            }
        },

    },
    effects: (dispatch) => ({

        async getModel1(payload, state) {
           
            return postData(config.url + '/charts/agegroups', {gender:state.filters.gender, gms:state.filters.gms }, state.user.access_token)
                .then(data => {
                    dispatch.charts.updateModel1(data);
                    return data;
                });
        },
        async getModel2(payload, state) {
            return getData(config.url + '/charts/model2', state.user.access_token)
                .then(data => {
                    return this.updateModel2(data)
                });
        }
    })
};