import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { Card, Box, Input } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BaseOptionChart } from "../../charts";

function AppLine(props) {
  const [chartData, setChartData] = useState([]);
  const [dateRange1, setDateRange1] = useState([]);
  const [monthRange1, setMonthRange1] = useState("");
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [dateEnd, setDateEnd] = useState(props.lastUpdata);

  useEffect(() => {
    if (chartData && chartData !== undefined) {
      // Trigger graph refresh when data changes
      setRefreshGraph((prev) => !prev);
    }
  }, [chartData]);

  useEffect(() => {
    const currentDate = new Date(dateEnd);
    const end1 = new Date(currentDate);
    end1.setMonth(end1.getMonth() - 1); // Subtracting one month for the date range

    const currentDateString = currentDate.toDateString();
    const [currentDatemonthStr] = currentDateString.split(" ");
    const end1String = end1.toDateString();
    const [end1monthStr] = end1String.split(" ");

    setMonthRange1(
      currentDatemonthStr === end1monthStr
        ? end1monthStr
        : end1monthStr + "-" + currentDatemonthStr
    );

    const dateRange = [];
    let currentDatePointer = new Date(end1);

    // Ensure UTC for consistency across devices
    while (currentDatePointer <= currentDate) {
      dateRange.push(currentDatePointer.toISOString().split("T")[0]); // Using ISO format to avoid time zone issues
      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }
    setDateRange1(dateRange);

    const data = props.audit3;

    const processedData = data.flatMap((yearData) =>
      Object.entries(yearData).map(([year, colors]) => {
        const greenData = colors["Green"];
        const redData = colors["Red"];

        const greenSeries = [];
        const redSeries = [];

        dateRange.forEach((date) => {
          const [yearStr, monthStr, dayStr] = date.split("-");
          const month = parseInt(monthStr);
          const day = parseInt(dayStr);

          greenData[month]
            ? greenData[month][day]
              ? greenSeries.push(greenData[month][day])
              : greenSeries.push(0)
            : greenSeries.push(0);

          redData[month]
            ? redData[month][day]
              ? redSeries.push(redData[month][day])
              : redSeries.push(0)
            : redSeries.push(0);
        });

        return [
          {
            name: `Green Antibiotics prescribed in ${year}`,
            data: greenSeries,
          },
          { name: `Red Antibiotics prescribed in ${year}`, data: redSeries },
        ];
      })
    );

    let furtherProceeded = processedData.flat();
    setChartData(furtherProceeded);
  }, [props.audit3, dateEnd]);

  const categories = dateRange1.map((date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}`;
  });

  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}: `,
        },
      },
    },
    chart: {
      type: "line",
      stacked: false,
      height: 364,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    colors: ["#26734D", "#cc3311", "#b3e6cc", "#f9c4b8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: categories,
      title: {
        text: monthRange1,
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 7,
      forceNiceScale: true,
    },
    legend: {
      show: false,
      position: "right",
      horizontalAlign: "right",
      floating: false,
      offsetY: -25,
      offsetX: -5,
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptionsLine}
          height={364}
          width="100%" // Make sure the graph is responsive
          key={refreshGraph}
        />
      </Box>
      <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
        <Input fullWidth multiline rows={8} defaultValue="Your analysis here" />
      </Box>
    </Card>
  );
}

const mapState = (state) => ({
  audit3: state.audit.audit3,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
