import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography, TextField, Button, Link, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { Form, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { string } from "prop-types";
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  p: 5,
}));

const initialValues = {
  password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('OTP is required'),
});

function InterOTP(props) {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [regenerated, setRegenerated] = useState(false);
  const [verifyCheck, setVerifyCheck] = useState(false);
  const [text, setText] = useState('');
  const [isRegenerateDisabled, setIsRegenerateDisabled] = useState(false); // New state for disabling the button
  const location = useLocation();

  // Accessing parameters from state
  const param1 = location.state?.param1;
  const param2 = location.state?.param2;
  const check = (param1 === "register") ? "register" : "forget";

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitted(true);
      setVerifyCheck(false);


      if (values.password) {
        let payload = {
          email: param2,
          otp: values.password,
          check: check
        }

        const response = await props.verifyOTP(payload);

        if (response == "OTP Verified") {

          if (param1 === "register") {
            navigate('/practiceDetails', { state: { param1: param2 } });
          } else {
            navigate('/forgetPassword', { state: { param1: param2 } });
          }
        } else {
          setVerifyCheck(true);
          setText(response);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
  } = formik;

  const handleRegenerateClick = async () => {
    // Add logic to regenerate OTP here

    if (!isRegenerateDisabled) {
      // Disable the button
      setIsRegenerateDisabled(true);
      setRegenerated(true);

      let payload = {
        email: param2,
        check: check
      }
      await props.resendOTP(payload);

      // Re-enable the button after 1 minute
      setTimeout(() => {
        setIsRegenerateDisabled(false);
        setRegenerated(false);
      }, 60000); // 60000 milliseconds = 1 minute
    }

  };

  return (
    <RootStyle title="CARA | Verify OTP">
      <Box >
        <AuthLayout />
      </Box>
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <Typography variant="h4" fontWeight="bold">
            Enter OTP
          </Typography>

          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type="text"
                label="Please enter OTP here"
                id="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={Boolean(submitted && touched.password && errors.password)}
                helperText={submitted && touched.password && errors.password}
              />
              <LoadingButton
                sx={{ marginTop: 3 }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>

        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right', marginTop: 2 }}>
          <Link onClick={handleRegenerateClick}>
            Regenerate OTP
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ color: 'red', marginTop: 3 }}>
          {verifyCheck ? text : ""}
        </Typography>
        <Typography variant="body1" sx={{ color: regenerated ? 'red' : 'text.primary' }}>
          {regenerated
            ? "Note: a new One-Time Password (OTP) has been sent to your email."
            : ""}
        </Typography>
      </Container>
    </RootStyle>
  );
}

const mapState = state => ({
});

const mapDispatch = ({
  user: { register, verifyOTP, resendOTP } }) => ({
    register: (payload) => register(payload),
    verifyOTP: (payload) => verifyOTP(payload),
    resendOTP: (payload) => resendOTP(payload),
  });

export default connect(mapState, mapDispatch)(InterOTP);


