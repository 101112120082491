import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------





function AppTypes(props) {
  const [typeYaxis, setTypeYaxis] = useState(0);
  const CHART_DATATypes = [{
    data: props.patients.gms_type,
  }]
  useEffect(() => {
    const maxY = Math.max(props.patients.gms_type[0],props.patients.gms_type[1]);
    setTypeYaxis(maxY + (maxY * 0.2));
  }, [props.patients.gms_type]);
  const chartOptions_TypesBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Number prescribed: `
        }
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: props.patients.gms_colors,
    plotOptions: {
      bar:
      {
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
        columnWidth: '60%',
        distributed: true,
      },

      chart: {
        brush: {
          enabled: false,
          target: 'TypesDonut',
          autoScaleYaxis: false,
          id: 'TypesBar',
          group: 'Types'
        },
        type: 'area',
        events: {
          dataPointSelection: (event, chartContext, config) => {
            console.log(chartContext, config);
          }
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.15,
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            }
          },
        }
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      formatter: function (val, opt) {
      const label_value = fNumber(val)
      return label_value
    },
      enabled: true,
      style: {
        colors: ['#708090']
      },
      offsetY: -20
    },
    xaxis: {
      categories: props.patients.gms_categories,
      colors: '#708090'
      // position: 'top',
    },
    yaxis: {
      show: false,
      max: typeYaxis,
    },
    stroke: {
      show: false,
    },
  });


  return (
    <Card sx={{ boxShadow: 'none', mt: 4 }}>
      <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATATypes} options={chartOptions_TypesBar} height={280} />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
import { useEffect, useState } from 'react';
const mapState = state => ({
  patients: state.overview.patients
});

const mapDispatch = ({
  overview: { loadData } }) => ({
    loadData: () => loadData()
  });

export default connect(mapState, mapDispatch)(AppTypes);
