export async function postData(url = '', data = {}, jwt) {
  // Default options are marked with *
  let headers;
  if (jwt) {
    headers = {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }
  else {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data ? JSON.stringify(data) : null // body data type must match "Content-Type" header
  });
  if (response.status !== 200) {
    // throw new Error(response.json().message || "Error");
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getData(url = '', jwt) {
  // Default options are marked with *
  let headers;
  if (jwt) {
    headers = {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }
  else {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  if (response.status !== 200) {
    if(response && response.message){
      throw new Error(response.json().message || "Error");
    }
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getDataWithBody(url = '', data = {}, jwt) {
  // Default options are marked with *
  let headers;
  if (jwt) {
    headers = {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }
  else {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  if (response.status !== 200) {
    if(response && response.message){
      throw new Error(response.json().message || "Error");
    }
  }
  return response.json(); // parses JSON response into native JavaScript objects
}


export function observeJWT(token, dispatch, reference) {
  dispatch.session.updateShowAlert(false);
  if (reference) {
    clearInterval(reference);
  }
  dispatch.session.updateTimeToExpire(60);
  let interval = setInterval(() => {
    let tte = timeToExpire(token)
    dispatch.session.updateTimeToExpire(tte);
    if (tte > 0 && tte < 30) {
      dispatch.session.updateShowAlert(true);
    }
    if (tte < 0) {
      dispatch.session.updateShowAlert(false);
      dispatch.user.logout();
    }
  }, 10000);
  dispatch.session.updateTimerReference(interval);
}
