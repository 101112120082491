import PropTypes from "prop-types";
import { Box, useMediaQuery, useTheme } from "@mui/material";

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const dynamicStyles = {
    height: isXs ? 233 : isMd ? 167 : 200,
    width: isXs ? 350 : isMd ? 250 : 300,
    ...sx,
  };

  return <Box component="img" src="/static/logo.jpg" sx={dynamicStyles} />;
}
