import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


export default async (name, ref) => {
    const element = ref.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 10, 10, pdfWidth - 10, pdfHeight - 10);
    pdf.save(name);
  };