import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box, Typography, Stack, Grid } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import Input from "@mui/material/Input";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//
import { BaseOptionChart } from "../../charts";
import { json } from "react-router-dom";

//data for the month of February both 2023 and 2022
const CHART_DATA_Line = [
  {
    name: "Green Antibiotics prescribed in 2023",
    data: [280, 290, 330, 150, 200, 133, 33, 67, 111, 187],
  },
  {
    name: "Red Antibiotics prescribed in 2023",
    data: [120, 110, 140, 180, 170, 130, 13, 55, 55, 69],
  },
  {
    name: "Green Antibiotics prescribed in 2022",
    data: [380, 190, 530, 50, 280, 163, 3, 78, 141, 157],
  },
  {
    name: "Red Antibiotics prescribed in 2022",
    data: [140, 90, 135, 150, 120, 150, 65, 42, 42, 60],
  },
];

function AppLine(props) {
  const [chartData, setChartData] = useState([{"name":"Green Antibiotics prescribed in 2024","data":[120,56,88,24,64,40,56,32,72,48,144,56,64,88,48,56,88,96,96,80,80,120,104,96,104,72,72,200,144,144,144,0]},{"name":"Red Antibiotics prescribed in 2024","data":[0,64,40,8,64,24,24,40,24,24,16,40,16,56,40,48,40,48,32,56,16,24,32,40,40,64,48,24,72,56,64,0]},{"name":"Green Antibiotics prescribed in 2023","data":[16,0,40,24,0,8,8,8,8,0,8,24,8,16,16,0,24,8,8,16,56,24,16,32,64,56,16,56,24,8,40,0]},{"name":"Red Antibiotics prescribed in 2023","data":[16,0,8,8,0,0,0,0,8,0,8,16,8,0,8,8,16,8,8,16,16,16,16,16,16,16,16,16,40,16,0,0]}]);
  const [dateRange1, setDateRange1] = useState([]);
  const [monthRange1, setMonthRange1] = useState("");
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [dateEnd, setDateEnd] = React.useState(props.lastUpdata);


  useEffect(() => {
    if (chartData && chartData !== undefined) {
      // If data has changed, trigger graph refresh
      setRefreshGraph((prev) => !prev);
    }
  }, [chartData]);

  // useEffect(() => {
  //   const currentDate = new Date(dateEnd);
  //   const end1 = new Date(currentDate);
  //   end1.setMonth(end1.getMonth() - 1);

  //   const currentDateString = currentDate.toDateString();
  //   const [
  //     currentDateweekDay,
  //     currentDatemonthStr,
  //     currentDatedayStr,
  //     currentDateyearStr,
  //   ] = currentDateString.split(" ");
  //   const end1String = end1.toDateString();
  //   const [end1weekDay, end1monthStr, end1dayStr, end1yearStr] =
  //     end1String.split(" ");
  //   currentDatemonthStr === end1monthStr
  //     ? setMonthRange1(end1monthStr)
  //     : setMonthRange1(end1monthStr + "-" + currentDatemonthStr);

  //   const dateRange = [];
  //   let currentDatePointer = new Date(end1);

  //   while (currentDatePointer <= currentDate) {
  //     dateRange.push(currentDatePointer.toLocaleDateString());
  //     currentDatePointer.setDate(currentDatePointer.getDate() + 1);
  //   }
  //   setDateRange1(dateRange);

  //   const data = props.audit3;

  //   const processedData = data.flatMap((yearData) =>
  //     Object.entries(yearData).map(([year, colors]) => {
  //       const greenData = colors["Green"];
  //       const redData = colors["Red"];

  //       const greenSeries = [];
  //       const redSeries = [];

  //       dateRange.forEach((date, index) => {
  //         const [monthStr, dayStr, yearStr] = date.split("/");
  //         const month = parseInt(monthStr);
  //         const day = parseInt(dayStr);

  //         greenData[month]
  //           ? greenData[month][day]
  //             ? greenSeries.push(greenData[month][day])
  //             : greenSeries.push(0)
  //           : greenSeries.push(0);

  //         redData[month]
  //           ? redData[month][day]
  //             ? redSeries.push(redData[month][day])
  //             : redSeries.push(0)
  //           : redSeries.push(0);
  //       });

  //       return [
  //         {
  //           name: `Green Antibiotics prescribed in ${year}`,
  //           data: greenSeries,
  //         },
  //         { name: `Red Antibiotics prescribed in ${year}`, data: redSeries },
  //       ];
  //     })
  //   );

  //   let furtherProceeded = processedData.flat();

  //   console.log("furtherProceeded",furtherProceeded);
  //   console.log(JSON.stringify(furtherProceeded));
  //   //setChartData(furtherProceeded);
  // }, []);

  const startMonth = 2; // Start from February
  const endMonth = 5; // End at May
  const categories = [];

  // dateRange1.forEach((data, index) => {
  //   const [monthStr, dayStr, yearStr] = data.split("/");
  //   const year = parseInt(yearStr);
  //   const month = parseInt(monthStr);
  //   const day = parseInt(dayStr);
  //   categories.push(`${day}/${month}`);
  // });

  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => seriesName + ": ",
        },
      },
    },
    chart: {
      type: "area",
      stacked: false,
      height: 364,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    colors: ["#26734D", "#cc3311", "#b3e6cc", "#f9c4b8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      // categories: categories,
      // // type: 'datetime',
      // title: {
      //   text: monthRange1,
      // },
    },
    yaxis: {
      min: 0,
      tickAmount: 7,
      forceNiceScale: true,
    },
    legend: {
      show: false,
      position: "right",
      horizontalAlign: "right",
      floating: false,
      offsetY: -25,
      offsetX: -5,
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <br />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptionsLine}
          height={364}
          key={refreshGraph}
        />
      </Box>
      <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
        <Input fullWidth multiline rows={8} defaultValue="Your analysis here" />
      </Box>
    </Card>
  );
}
const mapState = (state) => ({
  audit3: state.audit.audit3,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
